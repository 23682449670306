import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input, Injector } from "@angular/core"
import { Subscription } from "rxjs"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuarioEditCB,
    UsuarioEditCBForm,
    UsuarioAvatarEdit,
    UsuarioAvatarEditForm,
    UsuarioPaa,
    UsuarioPaaForm,
    UsuariosPaa
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { S3, S3Service, AuthService, BaseForm, GenericModalComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "usuarios-edit-cb",
    templateUrl: "usuarios_cb.edit.component.html",
    styleUrls: ["usuarios_cb.edit.component.scss"]
})
export class UsuariosCBEditComponent implements OnInit {
    usuario: Usuario
    usuarioEdit: UsuarioEditCB
    usuarioAvatarEdit: UsuarioAvatarEdit
    oUsuarioEdit: UsuarioEditCB
    oUsuarioAvatarEdit: UsuarioAvatarEdit

    paramsAvatar = UsuarioAvatarEditForm.formParams
    params = UsuarioEditCBForm.formParams

    @Input() usuarioId: number
    @Input() showFormPaa: boolean = true
    @Input() enableTutor: boolean = false

    form: UntypedFormGroup
    formAvatar: UntypedFormGroup

    edit_user_info: boolean = false
    edit_avatar: boolean = false
    edit_password: boolean = false
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutAvatar") loadingLayoutAvatar: LoadingLayoutComponent
    @ViewChild("cuentaEliminadaModal") cuentaEliminadaModal: GenericModalComponent

    loadingAvatar: boolean = false
    lugarLabel: string

    paaParams = UsuarioPaaForm.formParams
    usuario_paa: UsuarioPaa
    paaForm: UntypedFormGroup
    showAllFormPaa = false

    editingPaa = false

    deleteInput: string

    constructor(
        protected authService: AuthService,
        protected usuariosService: Usuarios,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        public s3Service: S3Service,
        protected injector: Injector,
        protected usuarioPaaService: UsuariosPaa
    ) {
        this.lugarLabel = config.plataforma.lugarLabel ? config.plataforma.lugarLabel : null
    }

    ngOnInit() {
        this.usuariosService.find(this.usuarioId, { include: "usuario_paa" }).then((usuario: Usuario) => {
            this.usuario = usuario
            this.usuarioEdit = new UsuarioEditCB(usuario.id)
            this.usuarioEdit.fromUsuario(usuario)
            this.oUsuarioEdit = this.usuarioEdit.clone()
            this.form = UsuarioEditCBForm.getForm(this.usuarioEdit, null, this.injector)

            this.usuarioAvatarEdit = new UsuarioAvatarEdit(usuario.id)
            this.usuarioAvatarEdit.fromUsuario(usuario)
            this.oUsuarioAvatarEdit = this.usuarioAvatarEdit.clone()
            this.formAvatar = UsuarioAvatarEditForm.getForm(this.usuarioAvatarEdit)

            if (this.showFormPaa) {
                this.usuario_paa = usuario.usuario_paa
                this.paaForm = UsuarioPaaForm.getForm(this.usuario_paa, null, this.injector)
                this.showAllFormPaa = this.usuario_paa.paa_tomada
            }

            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    saveProfileImage() {
        UsuarioAvatarEditForm.markFormControlsAsTouched(this.formAvatar)
        if (this.formAvatar.valid) {
            this.loadingLayoutAvatar.standby()
            this.s3Service.where(this.usuarioAvatarEdit.getS3Params()).then((policies: S3[]) => {
                let policy = policies as any as S3
                this.usuarioAvatarEdit["avatar"] = policy["key"]
                this.s3Service.uploadToS3(
                    policy,
                    this.usuarioAvatarEdit.file,
                    this.usuariosService.tableName,
                    this.updateUsuario
                )
            })
        }
    }

    updateUsuario = () => {
        this.usuariosService
            .update(this.usuarioAvatarEdit.usuario_id, this.usuarioAvatarEdit.toUsuario())
            .then((response: Usuario) => {
                this.authService.setUserData(response)
                this.usuario = response
                setTimeout(() => {
                    this.usuarioAvatarEdit["file"] = undefined
                    this.usuarioEdit["avatar"] = this.usuario.avatar
                    UsuarioEditCBForm.markFormControlsAsPristine(this.formAvatar)
                    UsuarioEditCBForm.markFormControlsAsUntouched(this.formAvatar)
                    this.cdr.detectChanges()
                    this.loadingAvatar = true
                }, 150)
            })
    }

    onLoadProfileImage() {
        if (this.loadingAvatar) {
            this.loadingLayoutAvatar.ready()
            this.loadingAvatar = false
        }
    }

    save() {
        UsuarioEditCBForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.usuariosService.update(this.usuarioEdit.id, this.usuarioEdit.toUsuario()).then(response => {
                this.authService.setUserData(response)
                this.router.navigate(["usuarios/" + this.usuarioEdit.id])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.usuarioEdit = this.oUsuarioEdit.clone()
            UsuarioEditCBForm.markFormControlsAsPristine(this.form)
            UsuarioEditCBForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    toggleUserInfo(event) {
        event.stopPropagation()
        this.edit_user_info = !this.edit_user_info
    }

    toggleAvatarEdit(event) {
        ;(document.getElementById("avatarInput").getElementsByClassName("inputfile-label")[0] as HTMLElement).click()
        event.stopPropagation()
    }

    cancelNewAvatar() {
        this.usuarioAvatarEdit["file"] = undefined
    }

    showEditPassword() {
        this.edit_password ? (this.edit_password = false) : (this.edit_password = true)
    }

    onChangePaaTomada(value) {
        this.showAllFormPaa = value == "true"
        this.usuario.usuario_paa.paa_tomada = this.showAllFormPaa
    }

    validarRespPaa() {
        this.showAllFormPaa = this.usuario_paa.paa_tomada
    }

    enviarPaa() {
        BaseForm.markFormControlsAsTouched(this.paaForm)
        if (this.paaForm.valid) {
            if (this.paaForm.controls.paa_tomada.value == "") {
                this.usuario_paa.omitido = true
            } else {
                this.usuario_paa.omitido = false
            }

            if (!this.usuario_paa.paa_tomada) {
                this.usuario_paa.resultado_ingles = null
                this.usuario_paa.resultado_matematicas = null
                this.usuario_paa.resultado_lectura_redaccion = null
                this.usuario_paa.year = null
                this.usuario_paa.ubicacion = null
            }
            this.usuarioPaaService.update(this.usuario_paa.id, this.usuario_paa).then(_ => {
                this.editingPaa = false
            })
        }
    }

    eliminarCuenta() {
        this.usuariosService.remove(this.authService.getUserData().id).then(u => {
            this.cuentaEliminadaModal.buttonPressed()
            this.authService.logout()
            this.router.navigate(["/landing"])
        })
    }
}
