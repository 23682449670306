<ng-container>
    <div class="row">
        <div class="col-md-12">
            <form-input
                [form]="form"
                [params]="params['paa_tomada']"
                [(value)]="usuario_paa['paa_tomada']"
                [key]="'paa_tomada'"
                (change)="validarResp()"
            ></form-input>
            <div *ngIf="showAllForm">
                <form-input
                    [form]="form"
                    [params]="params['year']"
                    [(value)]="usuario_paa['year']"
                    [key]="'year'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['ubicacion']"
                    [(value)]="usuario_paa['ubicacion']"
                    [key]="'ubicacion'"
                ></form-input>
                <label>¿Qué puntaje obtuviste?</label>
                <form-input
                    [form]="form"
                    [params]="params['resultado_matematicas']"
                    [(value)]="usuario_paa['resultado_matematicas']"
                    [key]="'resultado_matematicas'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['resultado_lectura_redaccion']"
                    [(value)]="usuario_paa['resultado_lectura_redaccion']"
                    [key]="'resultado_lectura_redaccion'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['resultado_ingles']"
                    [(value)]="usuario_paa['resultado_ingles']"
                    [key]="'resultado_ingles'"
                ></form-input>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <button (click)="enviar()" type="button" class="btn btn-default btn-block submit-btn">Enviar</button>
        </div>
        <div class="col-md-6">
            <button (click)="enviar()" type="button" class="btn btn-default btn-block submit-btn">Omitir</button>
        </div>
    </div>
</ng-container>
