import { SharedLayoutComponent } from "@puntaje/platforms/cb"
import { SimpleSharedLayoutComponent } from "@puntaje/platforms/cb"
import {
    UsuariosEditComponent,
    TutorConfirmarComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompletarRegistroComponent,
    RegisterConfirmationComponent,
    IncompleteRegisterComponent,
    RegisterComponent,
    CompletarRegistroSocialComponent,
    OpcionesRegistroNacimientoComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { UsuarioPerfilComponent } from "./usuario_perfil_paa.component"

import { UsuarioPaaComponent } from "./register/usuario-paa/usuario-paa.component"
import {
    UsuariosCBEditComponent,
    RegisterCompletarCbComponent,
    RegisterIncompletoPuntajeComponent,
    RegisterCBComponent,
    UsuarioCompletarRegistroSocialCbComponent
} from "@puntaje/puntaje/new-modules/usuarios"

export const routes: Routes = [
    //{ path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard] },
    /*{ path: 'usuarios/login', component: LoginComponent },*/
    {
        path: "usuarios/register",
        component: SharedLayoutComponent,
        children: [{ path: "", component: RegisterComponent, data: { component: RegisterCBComponent } }]
    },
    {
        path: "usuarios/registro",
        component: SharedLayoutComponent,
        children: [{ path: "", component: RegisterComponent, data: { component: RegisterCBComponent } }]
    },
    {
        path: "usuarios/opciones",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: OpcionesRegistroNacimientoComponent,
                data: {
                    validarIdentificador: false
                }
            }
        ]
    },
    {
        path: "usuarios/:id/completar_registro",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: CompletarRegistroComponent,
                data: {
                    component: RegisterCompletarCbComponent
                }
            }
        ]
    },
    {
        path: "usuarios/:id/completar_registro_social",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: CompletarRegistroSocialComponent,
                data: { component: UsuarioCompletarRegistroSocialCbComponent }
            }
        ]
    },
    { path: "usuarios/confirmar_email/:token", component: RegisterConfirmationComponent },
    {
        path: "usuarios/:id/informacion_paa",
        component: SharedLayoutComponent,
        children: [{ path: "", component: UsuarioPaaComponent }]
    },
    //{ path: 'usuarios/new', component: UsuariosNewComponent, canActivate: [AuthGuard] },
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [AuthGuard],
                data: {
                    component: UsuariosCBEditComponent
                }
            }
        ]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [AuthGuard],
                data: {
                    component: UsuariosCBEditComponent
                }
            }
        ]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        canActivate: [AuthGuard],
        children: [{ path: "", component: UsuarioPerfilComponent, data: { tab: "" } }]
    },
    {
        path: "usuarios/passwords/reset/:reset_password_token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ResetPasswordComponent }]
    },
    {
        path: "usuarios/passwords/forgot",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ForgotPasswordComponent, data: { useIdentificador: false } }]
    },
    /*{ path: 'usuarios/:id/logros', component: UsuarioComponent, canActivate: [AuthGuard], data: {tab: 'logros'} },*/
    //{ path: 'usuarios/:id/edit', component: UsuariosEditComponent, canActivate: [AuthGuard] },
    //{ path: 'usuarios/:id/edicion', component: EdicionComponent, canActivate: [AuthGuard] },
    //{ path: 'usuarios/:id/delete', component: UsuariosDeleteComponent, canActivate: [AuthGuard] }
    {
        path: "tutor/confirmar/:token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: TutorConfirmarComponent }]
    },
    {
        path: "pending_info",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: IncompleteRegisterComponent,
                data: { component: RegisterIncompletoPuntajeComponent }
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
