import { Component, OnInit, ViewChild, Output, EventEmitter, Injector, ChangeDetectorRef, Input } from "@angular/core"
import { UntypedFormGroup, Form } from "@angular/forms"
import { Router } from "@angular/router"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import {
    Usuarios,
    UsuarioRegistroCB,
    UsuarioRegistroCBForm,
    Establecimiento,
    Establecimientos,
    Lugar,
    Usuario
} from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { BaseForm, AuthService } from "@puntaje/shared/core"
import { BaseRegisterComponent } from "./base-register/base-register.component"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv

@Component({
    selector: "usuario-registro-cb",
    templateUrl: "register_cb.form.component.html",
    styleUrls: ["register_cb.component.scss"]
})
export class RegisterCBComponent extends BaseRegisterComponent implements OnInit {
    usuarioRegistro: UsuarioRegistroCB = new UsuarioRegistroCB()
    usuarioRegistroForm: UsuarioRegistroCBForm
    // params = UsuarioRegistroForm.formParams;
    params: any = UsuarioRegistroCBForm.formParams
    form: UntypedFormGroup
    save_button_text = "Guardar"
    saved: boolean = false
    lugar: Lugar

    captchaResponse: string
    captchaRequired: boolean = false
    captchaSiteKey: string
    errorCaptcha: string

    egresado: Clasificacion

    filteredEstablecimientos: Establecimiento[]

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()

    constructor(
        protected usuariosService: Usuarios,
        protected clasificacionesService: Clasificaciones,
        protected router: Router,
        protected injector: Injector,
        protected establecimientosService: Establecimientos,
        protected cdr: ChangeDetectorRef,
        protected loginService: Login,
        protected authService: AuthService
    ) {
        super(usuariosService, router, null, establecimientosService)
    }

    async ngOnInit() {
        this.captchaSiteKey = environment.recaptcha.siteKey
        this.captchaRequired = await this.usuariosService.captchaRequired()

        this.form = UsuarioRegistroCBForm.getForm(this.usuarioRegistro, null, this.injector)
        this.usuarioRegistro.fecha_nacimiento = JSON.parse(localStorage.getItem("fechaNacimiento"))
        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: "curso" },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })
    }

    beforeSave(usuario?: Usuario, tipoEnvio?: string) {
        this.usuariosService.enableIgnoreModel()
        ;(usuario as any).response = this.captchaResponse
    }

    afterSave(response) {
        if (response.captcha_required) {
            this.captchaRequired = true
            this.cdr.detectChanges()
            return null
        }

        this.usuariosService.disableIgnoreModel()
        return this.loginService.loginGeneral(
            Promise.resolve(this.authService.loginRegistroOPassword(response)),
            res => console.log(res),
            true,
            true
        )
        return null
    }

    resolved(response: string) {
        this.captchaResponse = response
        this.errorCaptcha = undefined
    }

    saveCondition() {
        let condition = this.form.valid

        if (this.captchaRequired) {
            const conditionCaptcha = this.captchaResponse != undefined || this.captchaResponse != null
            if (!conditionCaptcha) {
                this.errorCaptcha = "Recaptcha requerido."
            }

            condition &&= conditionCaptcha
        }

        return condition
    }
}
