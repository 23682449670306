import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura, TipoMaterial } from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig, TitleService } from "@puntaje/shared/core"
declare const config: AppConfig
import { select, Store } from "@ngrx/store"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"

@Component({
    templateUrl: "videoteca.component.html",
    styleUrls: ["videoteca.component.scss"]
})
export class VideotecaComponent implements OnInit, OnDestroy {
    asignatura: Asignatura
    cursoId: number = 1
    private sub: Subscription
    asignaturaId: number
    enableResponsive: boolean = false
    evaluacionTipo: string = "paa"
    materialTipoId: number = TipoMaterial.VIDEO
    @ViewChild("innerContent", { static: true }) loadingLayout: LoadingLayoutComponent

    asignaturaIds: number[]

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected store: Store<State>,
        protected loggedLayoutService: LoggedLayoutService,
        protected titleService: TitleService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("Evaluaciones")
        this.sub = this.route.queryParams.subscribe(query => {
            this.asignatura = null
            this.asignaturaId = +query["asignatura_id"]

            if (this.asignaturaId) {
                this.loadingLayout.ready()
            }

            this.filterAsignaturas()
        })
        this.enableResponsive = window.innerWidth <= 900

        this.loggedLayoutService.setOptions({})
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    async filterAsignaturas() {
        const asignaturasByEvaluacionTipo = await this.store
            .pipe(
                select(selectAsignaturasByEvaluacionTipo),
                filter(x => !!x),
                first()
            )
            .toPromise()
        const listaAsignaturas = asignaturasByEvaluacionTipo[this.evaluacionTipo]
        this.asignaturaIds = listaAsignaturas
            .map(a => a.id)
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onReadyCurso(curso_id) {
        this.cursoId = curso_id
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
    }

    onChangeAsignatura(asignaturaId) {
        this.loadingLayout.standby()
        this.asignaturaId = asignaturaId
        this.loadingLayout.ready()
    }
}
