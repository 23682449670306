<div class="showcasebox scroll">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div role="heading" aria-level="2" class="showcasebox_title_text">Practica para la PAA</div>
            <div class="showcasebox_title_icon">
                <img
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/home/v2/practica_icon.png"
                    alt="Practica para la PAA"
                    aria-hidden="true"
                />
            </div>
        </div>
    </div>
    <div class="showcasebox_content">
        <p class="info_text">Simula una práctica oficial de la PAA.</p>
        <div class="btn-mi-progreso">
            <a routerLink="/mi_progreso" [queryParams]="{ tipo_instrumento: 'paa' }">
                Mi progreso&nbsp;
                <fa name="chevron-right"></fa>
            </a>
        </div>
        <br />
        <vista-instrumentos-multiples [listaView]="true"></vista-instrumentos-multiples>
    </div>
</div>
