import { BaseForm, Validations, GroupValidator, AppConfig } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

declare const config: AppConfig

export class UsuariosCompletarRegistroSocialCbForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""

    public static get formParams(): any {
        const companyName = config.plataforma.info.companyName

        const params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            apellido_paterno: {
                label: "Apellido Paterno",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            apellido_materno: {
                label: "Apellido Materno",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            }
        }

        return params
    }
}
