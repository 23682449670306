<div class="resultado_puntaje">
    <loading-layout #lateralLoadingLayout>
        <div class="fade-lateral" *ngIf="evaluacionMultipleInstancia">
            <div class="resultado_puntaje_titulo" role="heading" aria-level="2">Resultados</div>
            <div class="resultado_puntaje_puntos_wrap">
                <div class="resultado_puntaje_puntos" *ngIf="calificacion != null && calificacion != undefined">
                    <ng-container
                        [ngSwitch]="evaluacionMultipleInstancia.evaluacion_multiple.evaluacion_tipo.evaluacion_tipo"
                    >
                        <div *ngSwitchCase="'curricular'">
                            <div class="resultado_puntaje_nota">
                                {{ calificacion | roundfloat }}
                            </div>
                        </div>
                        <div *ngSwitchCase="'evaluacion plan estudio'">
                            <div class="resultado_puntaje_porcentaje_numero">{{ calificacion | roundfloat }} %</div>
                        </div>
                        <div *ngSwitchCase="'diagnostico plan estudio'">
                            <div class="resultado_puntaje_porcentaje_numero">{{ calificacion | roundfloat }} %</div>
                        </div>
                        <div *ngSwitchCase="'paa'">
                            <div class="resultado_puntaje_porcentaje_numero">{{ calificacion | roundfloat: 1 }} %</div>
                        </div>
                        <div *ngSwitchCase="'Simulacro Saber Pro'">
                            <div class="resultado_puntaje_porcentaje_numero">{{ calificacion | roundfloat: 1 }}</div>
                        </div>
                        <div *ngSwitchDefault>
                            <div class="resultado_puntaje_puntos_numero">
                                {{ calificacion | roundfloat }}
                            </div>
                            <div class="resultado_puntaje_puntos_texto">puntos</div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="resultado_puntaje_valores">
                <table class="table table-striped">
                    <tbody>
                        <tr class="correctas_row">
                            <td>
                                <span class="glyphicon glyphicon glyphicon-ok-circle res-icon"></span>
                                <span class="res-icon-text">Correctas</span>
                            </td>
                            <td>{{ correctas }}</td>
                        </tr>
                        <tr class="incorrectas_row">
                            <td>
                                <span class="glyphicon glyphicon-remove-circle res-icon"></span>
                                Incorrectas
                            </td>
                            <td>{{ incorrectas }}</td>
                        </tr>
                        <tr class="omitidas_row">
                            <td>
                                <span class="glyphicon glyphicon-ban-circle res-icon"></span>
                                Omitidas
                            </td>
                            <td>{{ omitidas }}</td>
                        </tr>
                        <tr class="dudas_row">
                            <td>
                                <span class="glyphicon glyphicon-question-sign res-icon"></span>
                                Dudas
                            </td>
                            <td>{{ dudas }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="opciones-resultado clearfix"
                *ngIf="
                    evaluacionMultipleInstancia.evaluacion_multiple.evaluacion_tipo.evaluacion_tipo !==
                    'Simulacro Saber Pro'
                "
            >
                <ng-container *ngIf="asignaturasById$ | async as asignaturasById">
                    <p class="alert alert-info">
                        Te recomendamos repasar el componente de
                        <strong>{{ asignaturasById[peorAsignaturaId].asignatura }}</strong>
                    </p>

                    <p *ngFor="let asignaturaId of asignaturaIds" class="link-estudiar">
                        <ogr-icon
                            class="icono icono-asignatura {{ asignaturasById[asignaturaId]?.clase }}"
                            [name]="asignaturasById[asignaturaId]?.icono"
                        ></ogr-icon>
                        <a [routerLink]="['/planes_personales']" [queryParams]="{ asignatura_id: asignaturaId }">
                            Practicar
                            <strong>{{ asignaturasById[asignaturaId]?.asignatura }}</strong>
                        </a>
                    </p>
                </ng-container>
            </div>
            <div class="percentil" *ngIf="enablePercentil">
                <div class="barra-percentil">
                    <span class="separador"></span>
                    <span class="marcador" [style.left.%]="percentil"></span>
                    <span class="p0">0%</span>
                    <span class="p50">50%</span>
                    <span class="p100">100%</span>
                </div>
                <p class="info-percentil">
                    Te encuentras en el percentil
                    <b>60</b>
                    de estudiantes que rindieron esta evaluación.
                </p>
            </div>
        </div>
    </loading-layout>
</div>
