import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        achievements: urls.achievementsUrl
    },
    domain: {
        profesores: "docentes.paa.aprendolibre.com",
        alumnos: "paa.aprendolibre.com",
        admin: "admin.paa.aprendolibre.com"
    },
    googleAnalyticsPropertyId: {
        alumnos: "UA-167253923-1"
    },
    globalSiteTag: {
        alumnos: {
            propertyId: "G-JBV1H53DBN"
        }
    },
    facebookAnalyticsPropertyId: {},
    facebook: {
        appId: null,
        apiVersion: null
    },
    recaptcha: {
        siteKey: "6LdMw08cAAAAANAuZ3XqUqAOVOLi0VuPQF2V9Xxo"
    }
}
