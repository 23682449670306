import { style, animate, animation, trigger, state, transition, useAnimation } from "@angular/animations"

// Animaciones básicas

export const fadeInAnimation = animation([style({ opacity: 0 }), animate("{{ timings }}", style({ opacity: 1 }))])

export const slideInHorizontalAnimation = animation([
    style({ transform: "translateX({{ from }})", opacity: 0 }),
    animate("{{ timings }}", style({ transform: "translateX(0)", opacity: 1 }))
])

export const slideOutHorizontalAnimation = animation([
    style({ transform: "translateX(0)", opacity: 1 }),
    animate("{{ timings }}", style({ transform: "translateX({{ to }})", opacity: 0 }))
])

export const slideInVerticalAnimation = animation([
    style({ transform: "translateY({{ from }})", opacity: 0 }),
    animate("{{ timings }}", style({ transform: "translateY(0)", opacity: 1 }))
])

export const slideOutVerticalAnimation = animation([
    style({ transform: "translateY(0)", opacity: 1 }),
    animate("{{ timings }}", style({ transform: "translateY({{ to }})", opacity: 0 }))
])

// Animaciones por defecto

export function defaultFadeInOnScrolled() {
    return [
        state("pending", style({ opacity: 0 })),
        transition("pending => fadeIn", [
            useAnimation(fadeInAnimation, {
                params: {
                    timings: "0.3s 0ms ease-in"
                }
            })
        ])
    ]
}

export function defaultSlideInVerticalOnScrolled(from) {
    return [
        state("pending", style({ opacity: 0 })),
        transition("pending => slideIn", [
            useAnimation(slideInVerticalAnimation, {
                params: {
                    timings: "0.3s 0ms ease-in",
                    from: `${from}px`
                }
            })
        ])
    ]
}

export function defaultSlideInHorizontalOnScrolled(from) {
    return [
        state("pending", style({ opacity: 0 })),
        transition("pending => slideIn", [
            useAnimation(slideInHorizontalAnimation, {
                params: {
                    timings: "0.3s 0ms ease-in",
                    from: `${from}px`
                }
            })
        ])
    ]
}
