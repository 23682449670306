import { Component, HostBinding, OnDestroy } from "@angular/core"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { AuthService, TabService, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { trigger, transition, style, animate, keyframes } from "@angular/animations"

/*#################################

Barra de navegación que va en la parte superior.

#################################*/

@Component({
    templateUrl: "navbar.component.html",
    selector: "navbar",
    styleUrls: ["navbar.component.scss"],
    animations: [
        trigger("SlideDownAndUp", [
            transition("void => *", [
                animate(
                    "0.4s 0ms ease-in",
                    keyframes([
                        style({ marginTop: "-250px", opacity: "0", offset: 0 }),
                        style({ marginTop: "0", opacity: "1", offset: 1.0 })
                    ])
                )
            ]),
            transition("* => void", [
                animate(
                    "0.4s 0ms ease-in",
                    keyframes([
                        style({ marginTop: "0", opacity: "1", offset: 0 }),
                        style({ marginTop: "-250px", opacity: "0", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class NavbarComponent implements OnDestroy {
    @HostBinding("class.profile1") useProfile1 = false
    usuario_nombre: string
    usuario_apellido_paterno: string
    usuario_id: string
    usuario_avatar: string
    usuario_avatar_safe: SafeResourceUrl
    userUpdateSubscription: Subscription
    contactMail: string
    companyName: string
    showTopmenu = false

    config: typeof config

    constructor(
        protected authService: AuthService,
        protected router: Router,
        public sanitizer: DomSanitizer,
        public tabService: TabService,
        protected sessionService: SessionService
    ) {
        this.useProfile1 = this.authService.userProfile == "profile1"
        const usuario = this.authService.getUserData()
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar
            ? usuario.avatar + ""
            : "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/college_board/default-profile.png"
        this.usuario_avatar_safe = this.sanitize(this.usuario_avatar)
        this.contactMail = config.plataforma.info.contacto.mail
        this.companyName = config.plataforma.info.companyName
        this.userUpdateSubscription = this.sessionService.userDataUpdated.subscribe(usuario => {
            this.updateUserData(usuario)
        })

        this.config = config
    }

    logout() {
        this.authService.logout()
        const host = window.location.hostname
        const new_host = host
        // Redirect the user
        if (environment.production) {
            // new_host = host.replace(/^([a-z]+)\./g, "www.")
            window.location.href = "https://" + new_host
        } else {
            this.router.navigate(["/landing"])
        }
    }

    edit() {
        if (this.usuario_id != null) {
            this.router.navigate(["/usuarios/editar"])
        }
    }

    goToProfile() {
        if (this.usuario_id != null) {
            const base64Uid = btoa(this.usuario_id)
            this.tabService.setComponentTab("UsuarioComponent", "perfil")
            this.router.navigate(["/usuarios/" + base64Uid])
        }
    }

    sanitize(input: string): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(input)
    }

    updateUserData(usuario) {
        //usuario = this.authService.getUserData();
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : null
    }

    ngOnDestroy() {
        this.userUpdateSubscription.unsubscribe()
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    toggleTopmenu() {
        this.showTopmenu = !this.showTopmenu
    }

    skipToContent = (element: string) => {
        if (this.router.url == "/home") {
            this.router.navigate([], { fragment: element })
        }
        setTimeout(() => {
            document.getElementById(element).scrollIntoView()
        }, 40)
    }
}
