<div *ngIf="sessionService.getConfirmedAt() == 'null'" class="alert alert-warning alert-dismissible" role="region">
    <button type="button" class="close" data-dismiss="alert" aria-label="Cerrar">
        <span aria-hidden="true">&times;</span>
    </button>
    <strong>¡Advertencia!</strong>
    No ha confirmado su correo electrónico, por favor verificarlo.
</div>
<div id="main-content" class="row">
    <div class="col-md-4 col-sm-6">
        <videoteca-cb-showcasebox></videoteca-cb-showcasebox>
        <br />
        <planes-personales-cb-showcasebox></planes-personales-cb-showcasebox>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <instrumentos-cb-showcasebox></instrumentos-cb-showcasebox>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <evaluaciones-cb-showcasebox></evaluaciones-cb-showcasebox>
        <br />
        <evaluaciones-instancias-cb-showcasebox></evaluaciones-instancias-cb-showcasebox>
        <br />
    </div>
</div>

<noticias-modal [perfiles]="'todos,alumnos'"></noticias-modal>