<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div role="heading" aria-level="2" class="showcasebox_title_text">Prepárate para la PAA</div>
            <div class="showcasebox_title_icon">
                <img
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/home/v2/planes_icon.png"
                    alt="Planes de estudio personales"
                    aria-hidden="true"
                />
            </div>
        </div>
    </div>
    <div class="showcasebox_content">
        <p class="info_text">Conoce los tipos de ejercicios que encontrarás en la prueba y cómo debes enfrentarlos.</p>
        <br />
        <ul *ngIf="lista_asignaturas" class="lista-planes">
            <ng-container *ngFor="let a of lista_asignaturas">
                <li
                    *ngIf="a.asignaturaPlataforma !== 'Lectura' && a.asignaturaPlataforma !== 'Redacción'; else lectura"
                >
                    <a routerLink="/planes_personales" [queryParams]="{ asignatura_id: a.id }" class="{{ a.clase }}">
                        <ogr-icon [name]="a.icono" class="icono"></ogr-icon>
                        {{ a.asignaturaPlataforma }}
                    </a>
                </li>
                <ng-template #lectura>
                    <li *ngIf="a.asignaturaPlataforma === 'Lectura'; else redaccion">
                        <a routerLink="/plan_estudios/68663" class="{{ a.clase }}">
                            <ogr-icon [name]="a.icono" class="icono"></ogr-icon>
                            {{ a.asignaturaPlataforma }}
                        </a>
                    </li>
                    <ng-template #redaccion>
                        <li *ngIf="a.asignaturaPlataforma === 'Redacción'">
                            <a routerLink="/plan_estudios/68673" class="{{ a.clase }}">
                                <ogr-icon [name]="a.icono" class="icono"></ogr-icon>
                                {{ a.asignaturaPlataforma }}
                            </a>
                        </li>
                    </ng-template>
                </ng-template>
            </ng-container>
        </ul>
    </div>
</div>
