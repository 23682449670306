import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { Usuario, Usuarios, UsuarioView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { TabService, AuthService, TitleService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    templateUrl: "usuario_perfil_paa.component.html",
    styleUrls: ["usuario_perfil_paa.component.scss"]
})
export class UsuarioPerfilComponent implements OnInit, OnDestroy {
    usuario: Usuario
    private sub: Subscription
    params = UsuarioView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    openingTab: string = "perfil"
    currentTab: string = ""
    tabSubscription: Subscription
    pais: string
    idPais: any
    myUsuarioId: number
    isPrivate: boolean = true
    usuarioId: number

    constructor(
        protected usuariosService: Usuarios,
        protected router: Router,
        protected route: ActivatedRoute,
        protected sanitizer: DomSanitizer,
        protected tabService: TabService,
        protected authService: AuthService,
        protected titleService: TitleService
    ) {
        this.myUsuarioId = this.authService.getUserData().id
    }

    ngOnInit() {
        this.titleService.setTitle("Perfil")
        this.pais = config.plataforma.pais
        this.idPais = config.plataforma.identificadorUsuario
        this.sub = this.route.params.subscribe(params => {
            this.usuarioId = params["id"]
            this.usuariosService.find(this.usuarioId, { include: "usuario_" + this.pais }).then((usuario: Usuario) => {
                this.usuario = usuario
                this.isPrivate = !(this.usuario.id == this.myUsuarioId)
                if (this.isPrivate) this.router.navigateByUrl("/404")
                this.loadingLayout.ready()
            })
        })
        this.tabSubscription = this.tabService.tabSubject.subscribe(tabState => {
            if (tabState["UsuarioComponent"]) {
                this.openingTab = tabState["UsuarioComponent"]
                this.currentTab = this.openingTab
            }
        })
        this.currentTab = this.openingTab
        this.tabService.tabSubject.next(this.tabService.tabState)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.tabSubscription.unsubscribe()
    }

    setTabClasses(tab: string) {
        let classes = {
            active: this.openingTab == tab,
            in: this.openingTab == tab
        }
        return classes
    }

    setCurrentTab(tab: string) {
        this.currentTab = tab
    }
}
