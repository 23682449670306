import { Component, OnInit, Input, EventEmitter, ViewChild } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Noticias, Noticia, NoticiaInstancia, Usuario, NoticiaInstancias } from "@puntaje/puntaje/api-services"
import { GenericModalComponent, AuthService } from "@puntaje/shared/core"

@Component({
    selector: "noticias-modal",
    templateUrl: "./noticias-modal.component.html",
    styleUrls: ["./noticias-modal.component.scss"]
})
export class NoticiasModalComponent implements OnInit {
    @Input("perfiles") perfiles: string

    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent

    zona: string = "modal"
    storageKey: string = "noticiasModal"
    hasModalIcon: boolean = true
    openModal: EventEmitter<any> = new EventEmitter<any>()
    config: typeof config = config
    showModal: boolean = false
    noticias: Noticia[]
    noticiaPerfiles: string[] = ["todos"]
    usuarioId: number

    // carousel
    noShowIzq: boolean = true
    noShowDerec: boolean = true

    constructor(
        private noticiasService: Noticias,
        private noticiaInstanciasService: NoticiaInstancias,
        private authService: AuthService
    ) {}

    ngOnInit() {
        if (localStorage.getItem(this.storageKey)) {
            return
        }

        if (this.perfiles) {
            this.noticiaPerfiles = this.perfiles.split(",")
        }

        let today = new Date().toISOString()
        this.usuarioId = this.authService.getUserData().id

        const noticiasParams = {
            noticia: {
                tipo: ["login", "una_sola_vez"],
                lte: { fecha_inicio: today },
                gte: { fecha_termino: today }
            },
            no_vistas: 1,
            noticias_perfil: { noticia_perfil: this.noticiaPerfiles },
            sort_by: "created_at",
            order: "desc",
            shared_with_me: 1
        }
        this.noticiasService.where(noticiasParams).then((noticias: Noticia[]) => {
            if (noticias.length > 0) {
                this.noticias = noticias
                this.showModal = true
                this.genericModal.buttonPressed()
                this.onLoad()
            }
        })
    }

    onLoad() {
        localStorage.setItem(this.storageKey, "true")
    }

    showControler(indexes: number[]): void {
        if (indexes[0] == 0) {
            this.noShowIzq = true
            this.noShowDerec = false
        } else if (indexes[indexes.length - 1] == this.noticias.length - 1) {
            this.noShowDerec = true
            this.noShowIzq = false
        } else {
            this.noShowDerec = false
            this.noShowIzq = false
        }
    }

    saveInstancia(event: number) {
        const noticia = this.noticias[event]
        if (noticia.tipo == "una_sola_vez") {
            if (noticia.noticia_instancias == undefined || noticia.noticia_instancias == null) {
                noticia.noticia_instancias = []
            }

            if (noticia.noticia_instancias.length == 0) {
                let noticia_instancia = new NoticiaInstancia()
                noticia_instancia.noticia_id = noticia.id
                noticia_instancia.usuario_id = this.usuarioId
                noticia_instancia.fecha_visto = new Date()

                this.noticiaInstanciasService.save(noticia_instancia).then((noticia_instancia: NoticiaInstancia) => {
                    noticia.noticia_instancias.push(noticia_instancia)
                })
            }
        }
    }

    public close() {
        this.genericModal.close()
    }
}
