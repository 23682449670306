import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"
import { UsuarioChile } from "./usuario_chile.model"
import { UsuarioEdit } from "./usuarios_edit.model"

export class UsuarioEditCB extends UsuarioEdit {
    @SaveType(() => Lugar) public lugar: Lugar
    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType(() => Establecimiento) public establecimiento: Establecimiento

    constructor(id: number) {
        super(id)
    }

    public fromUsuario(usuario: Usuario) {
        super.fromUsuario(usuario)
    }

    public toUsuario() {
        super.toUsuario()

        return this.usuario
    }
}
