import { RestrictedLayoutComponent } from "../layouts/restricted_layout/restricted_layout.component"
import {
    EstadisticasEvaluacionMultipleComponent,
    EstadisticasProgresoComponent,
    EstadisticasAlumnoComponent,
    TutorEstadisticasComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { ProgresoCbComponent } from "./progreso_cb.component"

export const estadisticasRoutes: Routes = [
    {
        path: "estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasAlumnoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "tutor/estadisticas",
        component: RestrictedLayoutComponent,
        children: [{ path: "", component: TutorEstadisticasComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "mi_progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoCbComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "estadisticas/evaluaciones/:id/multiple",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasEvaluacionMultipleComponent, canActivate: [AuthGuard] }]
    }
]

export const estadisticasRouting = RouterModule.forChild(estadisticasRoutes)
