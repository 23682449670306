<nav class="login-nav">
    <cui-button
        (click)="toggleLogin()"
        [attr.aria-expanded]="showLogin"
        [attr.aria-controls]="showLogin ? 'login-box' : ''"
        class="btn_style"
    >
        Ingresa
    </cui-button>
    <cui-button (click)="toggleRegister()" class="btn_style">Regístrate</cui-button>
</nav>
<div class="login-box" *ngIf="showBox" id="login-box" @loginBox (@loginBox.done)="onAnimationDone($event)">
    <ng-container *ngIf="!isLoggedIn()">
        <ng-container *ngIf="showLogin">
            <form
                novalidate
                [formGroup]="form"
                [attr.aria-invalid]="invalidLogin"
                [attr.aria-describedby]="invalidLogin ? 'error-login' : ''"
                (ngSubmit)="login()"
            >
                <div class="mensaje-error" id="error-login" *ngIf="invalidLogin">
                    <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
                </div>
                <div class="login-inputs">
                    <div class="input-wrap">
                        <div class="form-group">
                            <label *ngIf="isAlCl" for="email">Inicia Sesión</label>
                            <label *ngIf="!isAlCl" for="email">Correo Electrónico</label>
                            <input
                                #emailInput
                                type="text"
                                name="email"
                                id="email"
                                [(ngModel)]="email"
                                [placeholder]="config.mensajesLogin.idPlaceholder"
                                *ngIf="!isLoggedIn()"
                                class="form-control"
                                formControlName="email"
                                [attr.aria-invalid]="checkEmail()"
                                [attr.aria-describedby]="checkEmail() ? 'error-email' : ''"
                            />
                            <div class="mensaje-error-input" id="error-email" *ngIf="checkEmail()">
                                <p>{{ config.mensajesLogin.invalidLoginId }}</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password">Contraseña</label>
                            <input
                                #passwordInput
                                type="password"
                                name="password"
                                id="password"
                                [(ngModel)]="password"
                                placeholder="Contraseña"
                                *ngIf="!isLoggedIn()"
                                class="form-control"
                                formControlName="password"
                                [attr.aria-invalid]="checkPassword()"
                                [attr.aria-describedby]="checkPassword() ? 'error-password' : ''"
                            />
                            <div class="mensaje-error-input" id="error-password" *ngIf="checkPassword()">
                                <p>Debes incluir una contraseña.</p>
                            </div>
                        </div>
                    </div>
                    <div class="center-captcha">
                        <div>
                            <re-captcha
                                *ngIf="captchaRequired"
                                size="compact"
                                (resolved)="captchaResolved($event)"
                                [siteKey]="captchaSiteKey"
                            ></re-captcha>
                            <error-messages
                                *ngIf="captchaRequired"
                                [isExpanded]="!!errorCaptcha"
                                containerId="captcha-error"
                            >
                                <ng-container *errorMessage>{{ errorCaptcha }}</ng-container>
                            </error-messages>
                        </div>
                    </div>

                    <div class="submit-wrap">
                        <cui-button type="submit" class="btn-ingresar" *ngIf="!isLoggedIn()">Ingresar</cui-button>
                        <google-button
                            [validarIdentificador]="validarIdentificador"
                            *ngIf="showLoginGoogle"
                            class="btn-google"
                        ></google-button>
                        <a
                            class="pwd-reset"
                            *ngIf="enablePasswordRecovery"
                            [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]"
                        >
                            ¿Olvidaste tu contraseña?
                        </a>
                    </div>
                </div>
            </form>
        </ng-container>
        <div class="register-wrap" *ngIf="showRegister">
            <cui-button [isBlock]="true" (click)="goToRegister(urlRegister)" class="btn-registro btn_style">
                Regístrate con tu correo
            </cui-button>
            <google-button *ngIf="showLoginGoogle" class="btn-google"></google-button>
            <a
                class="pwd-reset"
                *ngIf="enablePasswordRecovery"
                [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]"
            >
                ¿Olvidaste tu contraseña?
            </a>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoggedIn()">
        <div class="loggedIn-box">
            <div class="row">
                <div class="col-md-6">
                    <cui-button-link [isBlock]="true" class="btn_style btn-home" routerLink="/home">
                        <ogr-icon name="home-o" align="text-top" class="icono"></ogr-icon>
                        &nbsp;Home
                    </cui-button-link>
                </div>
                <div class="col-md-6">
                    <cui-button [isBlock]="true" buttonType="cancel" (click)="logout()" class="btn_style_2">
                        Cerrar sesión
                    </cui-button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<request-reset-password></request-reset-password>
