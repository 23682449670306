<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_text" aria-level="2" role="heading">Crea tus propias evaluaciones</div>
            <div class="showcasebox_title_icon">
                <img
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/home/v2/evaluaciones_icon.png"
                    alt="Crea tus propias evaluaciones"
                    aria-hidden="true"
                />
            </div>
        </div>
    </div>
    <div class="showcasebox_content">
        <p class="info_text">Personaliza el contenido, número de preguntas y tiempo de Tu práctica de la PAA.</p>
        <br />
        <p>
            <ng-container *ngIf="asignaturasByEvaluacionTipo$ | async as asignaturasByEvaluacionTipo">
                <a
                    [routerLink]="['/ensayos', 'generar_ensayo', asignaturasByEvaluacionTipo['paa'][0].id]"
                    class="ver-mas"
                >
                    Comenzar&nbsp;
                    <fa name="chevron-right"></fa>
                </a>
            </ng-container>
        </p>
    </div>
</div>
