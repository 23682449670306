import { Component, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"

@Component({
    selector: "app-usuario-paa",
    templateUrl: "./usuario-paa.component.html",
    styleUrls: ["./usuario-paa.component.scss"]
})
export class UsuarioPaaComponent implements OnInit {
    sub: Subscription
    usuarioId: number

    constructor(protected route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.usuarioId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
