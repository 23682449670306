import { BaseForm, Validations, DateHelper } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"

export class UsuarioEditCBForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "id", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Apellido materno",
                type: "text",
                visible: true,
                validations: [Validators.minLength(2)]
            },
            fecha_nacimiento: {
                label: "Fecha de nacimiento",
                type: "date",
                visible: true,
                min: DateHelper.firstDayYear(150),
                max: DateHelper.today()
            },
            email: {
                label: "Correo electrónico",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
