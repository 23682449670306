import { Directive, Output, ElementRef, HostListener, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core"

/******************
 *******************/

@Directive({
    selector: "[onScrolledToView]"
})
export class OnScrolledToViewDirective implements OnDestroy, OnInit {
    wasViewed: boolean = false
    @Output() enter = new EventEmitter()
    target: any
    @Input() padding: number = 0
    isBindedToParent: boolean = false
    @Input() partial = false

    constructor(protected el: ElementRef) {
        // Si la directiva está sobre un ng-container, aplica sobre el padre
        this.target = this.el.nativeElement.nodeType == 8 ? this.el.nativeElement.parentElement : this.el.nativeElement
        if (this.el.nativeElement.nodeType == 8) {
            window.addEventListener("scroll", this.onScroll.bind(this))
            this.isBindedToParent = true
        }
    }

    @HostListener("window:scroll", ["$event"]) checkFirstTimeScrolledIntoView() {
        this.onScroll()
    }

    ngOnInit() {
        // Para ver si inicialmente ya está visible
        this.onScroll()
    }

    onScroll() {
        if (this.wasViewed) return
        if (this.isScrolledIntoView()) {
            this.wasViewed = true
            this.enter.emit(this.wasViewed)
        }
    }

    isScrolledIntoView() {
        let rect = this.target.getBoundingClientRect()
        let elemTop = rect.top + this.padding
        var elemBottom = rect.bottom + this.padding

        if (!this.partial) return elemTop >= 0 && elemBottom <= window.innerHeight

        return elemTop < window.innerHeight && elemBottom >= 0
    }

    ngOnDestroy(): void {
        if (this.isBindedToParent) window.removeEventListener("scroll", this.onScroll.bind(this))
    }
}
