import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "sumTotalPercentage"
})
export class SumTotalPercentage implements PipeTransform {
    transform(obj: any, value: any): any {
        if (!obj[value] || obj[value] == undefined) return 0

        const result = obj[value].reduce(
            (acc: any, object: any) => {
                acc.correctas += object.correctas
                acc.total += object.correctas + object.incorrectas + object.omitidas
                return acc
            },
            { correctas: 0, total: 0 }
        )

        return ((1.0 * result.correctas) / result.total) * 100
    }
}
