import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura, Asignaturas, GeneradorInstrumentos, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { Evaluaciones, Evaluacion } from "@puntaje/puntaje/api-services"

import { AbstractGenerarEnsayoComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "generar_ensayo_simple.component.html"
})
export class GenerarEnsayoSimpleComponent {
    generadorInstrumento: GeneradorInstrumento

    subQuery: Subscription
    nombreEvaluacion: string
    tipoInstrumento: string

    config: typeof config

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.generar_ensayo")
        this.config = config

        this.subQuery = this.route.queryParams.subscribe(query => {
            const gi = query["generador_instrumento"]
            if (!gi) {
                this.router.navigate(["/404"])
            } else {
                const params = {
                    generador_instrumento: {
                        generador_instrumento: gi
                    },
                    with_tipo_instrumento: 1
                }
                this.generadorInstrumentosService
                    .where(params)
                    .then((generadorInstrumentos: GeneradorInstrumento[]) => {
                        if (generadorInstrumentos.length > 0) {
                            this.generadorInstrumento = generadorInstrumentos[0]

                            this.tipoInstrumento = this.generadorInstrumento.tipo_instrumento.tipo_instrumento
                            this.nombreEvaluacion = config.evaluaciones[this.tipoInstrumento].nombre
                        } else {
                            this.router.navigate(["/404"])
                        }
                    })
            }
        })
    }

    ngOnDestroy() {
        if (this.subQuery) this.subQuery.unsubscribe()
    }
}
