import { Component, Optional, ViewChild, ElementRef, OnInit, Input } from "@angular/core"
import { LoginBaseComponent } from "./login_base/login_base.component"
import { UntypedFormBuilder } from "@angular/forms"
import { Router } from "@angular/router"
import { AppEnv, AuthService, SessionService, I18nService } from "@puntaje/shared/core"
import { ReporteRazones, NebuAuthService } from "@puntaje/nebulosa/api-services"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { trigger, style, animate, transition, keyframes } from "@angular/animations"
import { HttpClient } from "@angular/common/http"

@Component({
    selector: "login-cb",
    templateUrl: "login_cb.component.html",
    styleUrls: ["login_cb.component.scss"],
    animations: [
        trigger("loginBox", [
            transition(":enter", [
                animate(
                    "0.3s ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateX(100px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
                    ])
                )
            ]),
            transition(":leave", [
                animate(
                    "0.3s ease-in",
                    keyframes([
                        style({ opacity: 1, transform: "translateX(0)", offset: 0 }),
                        style({ opacity: 0, transform: "translateX(100px)", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class LoginCBComponent extends LoginBaseComponent implements OnInit {
    @ViewChild("emailInput") emailInput: ElementRef
    @ViewChild("passwordInput") passwordInput: ElementRef
    showLoginGoogle = false
    showBox = false
    showLogin = false
    showRegister = false
    isAlCl = false
    @Input() urlRegister = "usuarios/opciones"
    @Input() validarIdentificador = true

    constructor(
        formBuilder: UntypedFormBuilder,
        authService: AuthService,
        sessionService: SessionService,
        nebuAuthService: NebuAuthService,
        reporteRazonesService: ReporteRazones,
        usuariosService: Usuarios,
        simpleModalService: SimpleModalService,
        store: Store<State>,
        router: Router,
        @Optional() carrerasAuthService: CarrerasAuthService,
        loginService: Login,
        http: HttpClient,
        protected i18nService: I18nService
    ) {
        super(
            formBuilder,
            authService,
            sessionService,
            nebuAuthService,
            reporteRazonesService,
            usuariosService,
            simpleModalService,
            store,
            router,
            carrerasAuthService,
            loginService,
            http,
            i18nService
        )
    }

    async ngOnInit() {
        this.config.plataforma.name == "Aprendolibre" ? (this.isAlCl = true) : (this.isAlCl = false)
        this.showLoginGoogle = this.config.plataforma.loginGoogle ? this.config.plataforma.loginGoogle : false

        this.captchaRequired = await this.usuariosService.captchaRequired()
    }

    toggleLogin() {
        this.showLogin = !this.showLogin
        if (this.showLogin) this.showRegister = false
        this.showBox = this.showLogin || this.showRegister
    }
    toggleRegister() {
        this.showRegister = !this.showRegister
        if (this.showRegister) this.showLogin = false
        this.showBox = this.showLogin || this.showRegister
    }

    onAnimationDone(event) {
        if (event.fromState == "void" && this.showLogin) {
            this.setEmailFocus()
        }
    }

    setEmailFocus() {
        this.emailInput.nativeElement.focus()
    }

    setPasswordFocus() {
        this.passwordInput.nativeElement.focus()
    }
}
