import { BaseForm, Validations, GroupValidator, DateHelper, AppConfig } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"

import { UsuariosCompletarRegistroChileForm } from "./usuarios_completar_registro_chile.form"
import { UsuariosCompletarRegistroColombiaForm } from "./usuarios_completar_registro_colombia.form"
import { Paises } from "./paises.service"
import { UsuariosCompletarRegistroMexicoForm } from "./usuarios_completar_registro_mexico.form"
import { HttpClient } from "@angular/common/http"

declare const config: AppConfig

export class UsuariosCompletarRegistroCbForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""

    public static get formParams(): any {
        let companyName = config.plataforma.info.companyName

        let params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            nombre: {
                label: "Nombre(s)",
                type: "text",
                visible: true,
                validations: [Validators.required]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required]
            },
            fecha_nacimiento: {
                label: "Fecha de nacimiento",
                type: "birthdate",
                visible: true,
                min: DateHelper.firstDayYear(150),
                max: DateHelper.today()
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            pais_id: { label: "País", type: "select", visible: true, options: { class: Paises } },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                validations: [Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                global: true,
                validations: [Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            }
        }

        let classes = {
            chile: UsuariosCompletarRegistroChileForm,
            colombia: UsuariosCompletarRegistroColombiaForm,
            mexico: UsuariosCompletarRegistroMexicoForm
        }

        params["usuario_" + config.plataforma.pais] = { type: "model", class: classes[config.plataforma.pais] }

        return params
    }
}
