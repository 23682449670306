<ll-titulo>Resultados</ll-titulo>

<loading-layout>
    <div class="table-resultados" *ngIf="!loadingLayout.loading">
        <ng-container *ngFor="let ec of estadisticaClasificaciones">
            <div class="table-resultados-row">
                <div class="table-resultados-cell">
                    {{ ec.clasificacion.clasificacion }} (Porcentaje de acierto: {{ 100 * ec.desempeno | roundfloat }}%)
                </div>
            </div>
            <ng-container
                *ngFor="let evaluacionId of preguntasByClasificacionIdEvaluacionId[ec.clasificacion.id] | keys"
            >
                <ng-container
                    *ngFor="let pregunta of preguntasByClasificacionIdEvaluacionId[ec.clasificacion.id][evaluacionId]"
                >
                    <div class="table-resultados-row">
                        <div class="table-resultados-cell">
                            {{ evaluacionByEvaluacionId[evaluacionId].evaluacion }}, Pregunta #{{
                                instrumentoPreguntaByPreguntaId[pregunta.id].orden + 1
                            }}
                        </div>
                        <div class="table-resultados-cell">
                            <button (click)="ver(pregunta)">Ver</button>
                        </div>
                    </div>
                    <div class="table-resultados-row" *ngIf="preguntaFeedback == pregunta">
                        <div *ngIf="preguntaFeedback.grupo_pregunta">
                            <div
                                [innerHTML]="preguntaFeedback.grupo_pregunta.texto | clearLatex | katex | trustedhtml"
                            ></div>
                        </div>
                        <div>
                            Enunciado:
                            <div
                                [innerHTML]="preguntaFeedback.taxativos[0]?.taxativo | clearLatex | katex | trustedhtml"
                            ></div>
                        </div>
                        <div>
                            Tu respuesta:
                            <ng-container *ngIf="alternativaByPreguntaId[preguntaFeedback.id]">
                                <div
                                    [innerHTML]="
                                        alternativaByPreguntaId[preguntaFeedback.id].alternativa
                                            | clearLatex
                                            | katex
                                            | trustedhtml
                                    "
                                ></div>
                            </ng-container>
                            <ng-container *ngIf="!alternativaByPreguntaId[preguntaFeedback.id]">
                                <div>-</div>
                            </ng-container>
                        </div>
                        <div>
                            Solución:
                            <div [innerHTML]="preguntaFeedback.solucion | clearLatex | katex | trustedhtml"></div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</loading-layout>
