import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { SessionService, TitleService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent implements OnInit {
    config: typeof config
    @Input() pagina: string = "home"
    isPro = false
    dias = false
    dias_faltantes = 0
    frase: string = ""
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;
    asignaturasBoxInsteadPlanClase = false

    constructor(
        protected titleService: TitleService,
        protected router: Router,
        protected route: ActivatedRoute,
        public sessionService: SessionService,
        protected usuariosService: Usuarios,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        this.config = config
    }

    ngOnInit() {
        this.titleService.setTitle("Home")
        this.isPro = this.sessionService.isPro()

        this.loggedLayoutService.setOptions({ freeContentStyle: true, noTitleBar: true })
    }
}
