<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_text" aria-level="2" role="heading">Conoce la PAA</div>
            <div class="showcasebox_title_icon">
                <img
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/home/v2/conoce_icon.png"
                    alt="Conoce la PAA"
                    aria-hidden="true"
                />
            </div>
        </div>
    </div>
    <div class="showcasebox_content">
        <p class="info_text">Descubre las principales características de la PAA.</p>
        <br />
        <ul>
            <li *ngFor="let a of lista_asignaturas" class="{{ a.clase }}">
                <ogr-icon name="video" class="iconoAsignatura"></ogr-icon>
                <a class="asignatura" routerLink="/videoteca" [queryParams]="{ asignatura_id: a.id }">
                    {{ a.asignatura }}
                </a>
                <cui-button-link class="btn_style" routerLink="/videoteca" [queryParamsValue]="{ asignatura_id: a.id }">
                    Ver&nbsp;
                    <fa name="arrow-right" class="icono"></fa>
                </cui-button-link>
            </li>
        </ul>
        <br />
        <a routerLink="/videoteca" class="ver-mas">
            Ver más&nbsp;
            <fa name="chevron-right"></fa>
        </a>
    </div>
</div>
