import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "progreso_cb.component.html",
    styleUrls: ["progreso_cb.component.scss"]
})
export class ProgresoCbComponent {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number
    private sub: Subscription
    enableResponsive: boolean = false
    tipoInstrumento: string

    constructor(protected route: ActivatedRoute, protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("Progreso")
        this.sub = this.route.queryParams.subscribe(queryParams => {
            this.tipoInstrumento = queryParams["tipo_instrumento"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
