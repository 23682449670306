<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_text" aria-level="2" role="heading">Historial</div>
            <div class="showcasebox_title_icon">
                <img
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/home/v2/historial_icon.png"
                    alt="Historial"
                    aria-hidden="true"
                />
            </div>
        </div>
    </div>
    <div class="showcasebox_content">
        <p class="info_text">Consulta las evaluaciones que has realizado.</p>
        <br />
        <p>
            <a [routerLink]="['/pruebas/resultados/']" class="ver-mas">
                Ver más&nbsp;
                <fa name="chevron-right"></fa>
            </a>
        </p>
    </div>
</div>
