import { Component, HostBinding } from "@angular/core"

@Component({
    selector: "instrumentos-cb-showcasebox",
    templateUrl: "instrumentos_cb_showcasebox.component.html",
    styleUrls: ["instrumentos_cb_showcasebox.component.scss"]
})
export class InstrumentosCBShowcaseBoxComponent {
    @HostBinding("class") innerClass = "showcasebox_style_1"
}
