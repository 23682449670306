import { Component, OnInit, Input, Injector } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { UsuarioPaaForm, UsuarioPaa, UsuariosPaa } from "@puntaje/puntaje/api-services"
import { BaseForm } from "@puntaje/shared/core"
import { Router } from "@angular/router"

@Component({
    selector: "usuario-paa",
    templateUrl: "./usuario-paa.component.html",
    styleUrls: ["./usuario-paa.component.scss"]
})
export class UsuarioPaaComponent implements OnInit {
    @Input() usuarioId
    params: any = UsuarioPaaForm.formParams
    usuario_paa: UsuarioPaa = new UsuarioPaa()
    form: UntypedFormGroup
    showAllForm: boolean = false

    constructor(protected injector: Injector, protected router: Router, protected usuarioPaaServices: UsuariosPaa) {}

    ngOnInit() {
        this.form = UsuarioPaaForm.getForm(this.usuario_paa, null, this.injector)
        this.usuario_paa.usuario_id = this.usuarioId
    }

    validarResp() {
        this.showAllForm = false
        if (this.usuario_paa.paa_tomada == true) {
            this.showAllForm = true
        }
    }

    enviar() {
        BaseForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            if (this.form.controls.paa_tomada.value == "") {
                this.usuario_paa.omitido = true
            } else {
                this.usuario_paa.omitido = false
            }
            this.usuarioPaaServices.save(this.usuario_paa).then(response => {
                this.router.navigate(["/home"])
            })
        }
    }
}
