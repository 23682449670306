import { Directive, HostListener, ElementRef } from "@angular/core"

@Directive({
    selector: "[focusInvalidInput]"
})
export class FocusInvalidInputDirective {
    constructor(private el: ElementRef) {}

    @HostListener("submit")
    onFormSubmit() {
        let invalidElements = this.el.nativeElement.querySelectorAll(
            "input.ng-invalid,select.ng-invalid,textarea.ng-invalid"
        )
        if (invalidElements.length > 0) {
            invalidElements[0].focus()
        }
    }
}
