<ng-container *ngIf="!saved && form">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuario['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuario['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_materno']"
                    [(value)]="usuario['apellido_materno']"
                    [key]="'apellido_materno'"
                ></form-input>
            </div>

            <div class="col-md-6">
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Actualizar</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos actualizado correctamente tus datos. Te redirigemos a la página principal en un instante.
</ng-container>
