<footer>
    <div class="top-footer">
        <p>
            <span>En alianza con</span>
            <a href="https://latam.collegeboard.org" target="_blank">
                <img
                    class="partnership-logo"
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/logos/logo_cb_footer.png"
                    alt="El College Board"
                />
            </a>
        </p>
    </div>
    <div class="footer-wrap clearfix">
        <div class="row">
            <div class="col-md-4 footer-col">
                <div class="h3" role="heading" aria-level="3">Sobre este sitio</div>
                <ul>
                    <li><a routerLink="/condicionesUso">Términos y condiciones</a></li>
                </ul>
            </div>
            <div class="col-md-4 footer-col">
                <ul>
                    <li>
                        <h4>¿Tienes dudas? Escríbenos a:</h4>
                    </li>
                    <li>
                        <span class="icono email"><fa [name]="'envelope'"></fa></span>
                        <a href="{{ supportMail ? 'mailto:' + supportMail : '' }}">
                            <span class="info">{{ supportMail }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 footer-col">
                <a href="https://www.aprendolibre.com">
                    <img
                        class="footer-logo"
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_com/logos/logoLargeLight.png"
                        alt="paa.aprendolibre.com"
                    />
                </a>
            </div>
        </div>
    </div>
</footer>
