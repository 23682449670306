import { Component, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "terms-and-conditions-cb",
    templateUrl: "./terms-and-conditions-cb.component.html",
    styleUrls: ["./terms-and-conditions-cb.component.scss"]
})
export class TermsAndConditionsCBComponent implements OnInit {
    companySite: string
    companyName: string

    constructor() {
        let cfg = config
        this.companyName = config.plataforma.info.companyName
        this.companySite = config.plataforma.info.companySite
    }

    ngOnInit() {}
}
