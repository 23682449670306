import { Component, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import {
    Estadisticas,
    EvaluacionInstancias,
    EvaluacionInstancia,
    EvaluacionMultiples,
    EvaluacionMultiple,
    Evaluacion,
    InstrumentoPregunta
} from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { AuthService } from "@puntaje/shared/core"
import { ClasificacionTipos, ClasificacionTipo, Preguntas, Pregunta, Alternativa } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./estadisticas_evaluacion_multiple.component.html"
})
export class EstadisticasEvaluacionMultipleComponent implements OnInit {
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    sub: Subscription
    subQuery: Subscription

    preguntaFeedback: Pregunta
    evaluacionMultipleId: number
    asignaturaId: number
    clasificacionTipo: string

    evaluacionInstancias: EvaluacionInstancia[]
    estadisticaClasificaciones: any[]
    evaluacionByEvaluacionId: { [id: number]: Evaluacion }
    evaluacionByPreguntaId: { [id: number]: Evaluacion }
    instrumentoPreguntaByPreguntaId: { [id: number]: InstrumentoPregunta }
    preguntasByClasificacionIdEvaluacionId: { [id: number]: { [evaluacionId: number]: Pregunta[] } }
    alternativaByPreguntaId: { [id: number]: Alternativa }

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected clasificacionTiposService: ClasificacionTipos,
        protected estadisticasService: Estadisticas,
        protected preguntasService: Preguntas,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        protected evaluacionInstanciasService: EvaluacionInstancias
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.estadisticas_evaluacion_multiple")
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionMultipleId = +params["id"]
        })

        this.subQuery = this.route.queryParams.subscribe(queryParams => {
            this.asignaturaId = +queryParams["asignatura_id"]
            this.clasificacionTipo = queryParams["clasificacion_tipo"]

            this.getData()
        })
    }

    getData() {
        this.loadingLayout.standby()

        let params: any = {
            instrumento: {
                asignatura_id: this.asignaturaId
            }
        }

        let usuarioId = this.authService.getUserData().id

        this.evaluacionMultiplesService
            .find(this.evaluacionMultipleId, params)
            .then(async (evaluacionMultiple: EvaluacionMultiple) => {
                console.log(evaluacionMultiple)
                let clasificacionTipo = (
                    await this.clasificacionTiposService.where({
                        clasificacion_tipo: { clasificacion_tipo: this.clasificacionTipo }
                    })
                )[0] as ClasificacionTipo

                let evaluacionIds = evaluacionMultiple.evaluaciones.map(e => e.id)

                params = {
                    evaluacion_instancia: {
                        evaluacion_id: evaluacionIds,
                        usuario_id: usuarioId,
                        oficial: 1
                    },
                    include: "[respuestas,evaluacion:[instrumento:[instrumento_preguntas]]]"
                }
                let evaluacionInstancias = (await this.evaluacionInstanciasService.where(
                    params
                )) as EvaluacionInstancia[]
                this.evaluacionByEvaluacionId = evaluacionInstancias.reduce((acc, ei) => {
                    acc[ei.evaluacion_id] = ei.evaluacion

                    return acc
                }, {})
                this.evaluacionByPreguntaId = evaluacionInstancias.reduce((acc, ei) => {
                    ei.evaluacion.instrumento.instrumento_preguntas.forEach(ip => {
                        acc[ip.pregunta_id] = ei.evaluacion
                    })

                    return acc
                }, {})
                this.instrumentoPreguntaByPreguntaId = evaluacionInstancias.reduce((acc, ei) => {
                    ei.evaluacion.instrumento.instrumento_preguntas.forEach(ip => {
                        acc[ip.pregunta_id] = ip
                    })

                    return acc
                }, {})
                console.log(evaluacionInstancias)

                params = {
                    collection: "EstadisticaInstanciaClasificacion",
                    estadistica: {
                        evaluacion_instancia_id: evaluacionInstancias.map(ei => ei.id)
                    }
                }

                let estadisticaInstanciaClasificaciones = (await this.estadisticasService.where(params)) as any[]

                console.log(estadisticaInstanciaClasificaciones)

                this.estadisticaClasificaciones = estadisticaInstanciaClasificaciones.reduce((acc, eic) => {
                    if (acc.length != 0) {
                        acc.forEach(ec => {
                            let estadisticaClasificacion = eic.estadistica_clasificaciones.find(
                                ecl => ecl.clasificacion_id == ec.clasificacion_id
                            )

                            if (estadisticaClasificacion) {
                                ec.correctas += estadisticaClasificacion.correctas
                                ec.incorrectas += estadisticaClasificacion.incorrectas
                                ec.omitidas += estadisticaClasificacion.omitidas
                            }
                        })
                    } else {
                        acc = eic.estadistica_clasificaciones
                    }

                    return acc
                }, [])

                this.estadisticaClasificaciones = this.estadisticaClasificaciones.filter(
                    ec => ec.clasificacion.clasificacion_tipo_id == clasificacionTipo.id
                )
                let preguntaIds = evaluacionInstancias.reduce((acc, ei) => {
                    return acc.concat(ei.evaluacion.instrumento.instrumento_preguntas.map(ip => ip.pregunta_id))
                }, [])

                const allRespuestas = evaluacionInstancias.reduce((acc, ei) => acc.concat(ei.respuestas), [])
                console.log(preguntaIds)
                let preguntas = await this.preguntasService.whereWithAlternativasLibres(
                    {
                        pregunta: { id: preguntaIds },
                        clasificacion_tipo: { id: clasificacionTipo.id },
                        with_clasificaciones: 1
                    },
                    allRespuestas
                )
                this.preguntasByClasificacionIdEvaluacionId = preguntas.reduce((acc, p) => {
                    let clasificacion = p.clasificaciones.find(c => c.clasificacion_tipo_id == clasificacionTipo.id)
                    acc[clasificacion.id] = acc[clasificacion.id] || {}
                    let evaluacion = this.evaluacionByPreguntaId[p.id]

                    acc[clasificacion.id][evaluacion.id] = acc[clasificacion.id][evaluacion.id] || []
                    acc[clasificacion.id][evaluacion.id].push(p)

                    return acc
                }, {})

                let alternativaIdByPreguntaId = allRespuestas.reduce((acc, r) => {
                    acc[r.pregunta_id] = r.alternativa_id

                    return acc
                }, {})
                this.alternativaByPreguntaId = preguntas.reduce((acc, p) => {
                    acc[p.id] = p.contestables[0]?.alternativas.find(a => alternativaIdByPreguntaId[p.id] == a.id)

                    return acc
                }, {})

                console.log(this.preguntasByClasificacionIdEvaluacionId)

                this.estadisticaClasificaciones.forEach(ec => {
                    ec.desempeno = ec.correctas / (ec.correctas + ec.incorrectas + ec.omitidas)
                })

                console.log(this.estadisticaClasificaciones)
                this.loadingLayout.ready()
            })
    }

    ver(pregunta) {
        if (this.preguntaFeedback == pregunta) {
            this.preguntaFeedback = null
        } else {
            this.preguntaFeedback = pregunta
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQuery.unsubscribe()
    }
}
