<nav class="navbar" aria-label="Navegación Usuario">
    <div class="container-fluid">
        <div class="navbar-content">
            <div class="navbar-left navbar-logo-container">
                <div class="navbar-logo-container-inner">
                    <a routerLink="/home">
                        <img
                            class="navbar-logo"
                            src="{{ config.app.assets.logoSmallLight }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
            <button title="Menú" type="button" class="navbar-toggle" data-toggle="collapse" data-target=".user-menu">
                <span aria-hidden="true" class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="skip-to-content-link" (click)="skipToContent('main-content')" href="javascript:void(0)">
                Saltar navegación
            </a>
            <div class="user-menu collapse">
                <ul class="nav navbar-top-links navbar-right">
                    <li>
                        <a routerLink="/home" role="button">Inicio</a>
                    </li>
                    <li>
                        <a (click)="goToProfile()" href="javascript:void(0)">
                            <span title="{{ usuario_nombre | easyplaceholder: 'Usuario' }}" class="user-name">
                                {{ usuario_nombre | easyplaceholder: "Usuario" }}
                            </span>
                        </a>
                    </li>
                    <li class="dropdown last-list-item">
                        <a class="dropdown-toggle" aria-label="Menú de usuario" data-toggle="dropdown" href="#">
                            <div class="clearfix last-item-cont">
                                <div class="user-circle-container pull-left">
                                    <img
                                        *ngIf="usuario_avatar"
                                        class="avatar"
                                        aria-label="Avatar Usuario"
                                        [src]="usuario_avatar_safe"
                                    />
                                    <fa
                                        *ngIf="!usuario_avatar"
                                        class="fa-placeholder"
                                        aria-label="Avatar Usuario por Defecto"
                                        [name]="'user'"
                                    ></fa>
                                </div>
                                <div class="pull-right little-arrow-user">
                                    <fa [name]="'sort-desc'"></fa>
                                </div>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-user">
                            <li>
                                <a (click)="edit()" href="javascript:void(0)">Editar cuenta</a>
                            </li>
                            <li>
                                <a (click)="logout()" href="javascript:void(0)">Cerrar sesión</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
