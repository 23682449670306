import { Component, HostBinding, OnInit } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { select, Store } from "@ngrx/store"
import { filter, first } from "rxjs/operators"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { Observable } from "rxjs"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "evaluaciones-cb-showcasebox",
    templateUrl: "evaluaciones_cb_showcasebox.component.html",
    styleUrls: ["evaluaciones_cb_showcasebox.component.scss"]
})
export class EvaluacionesCBShowcaseBoxComponent implements OnInit {
    usuario_id: string
    @HostBinding("class") innerClass = "showcasebox_style_1"

    asignaturasByEvaluacionTipo$: Observable<{ [tipoEvaluacion: string]: AsignaturaWithConfig[] }> = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x),
        first()
    )

    constructor(protected authService: AuthService, protected store: Store<State>) {
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id
    }

    ngOnInit() {}
}
