import { Component, OnInit, Input, HostBinding } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { select, Store } from "@ngrx/store"
import { filter, first } from "rxjs/operators"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "videoteca-cb-showcasebox",
    templateUrl: "videoteca_cb_showcasebox.component.html",
    styleUrls: ["videoteca_cb_showcasebox.component.scss"]
})
export class VideotecaCBShowcaseBoxComponent implements OnInit {
    usuario_id: string
    @Input() lista_asignaturas: any
    @Input() tipoEvaluacion: string
    @HostBinding("class") innerClass = "showcasebox_style_1"

    constructor(protected authService: AuthService, protected store: Store<State>) {
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id
    }

    ngOnInit() {
        this.setAsignaturas()
    }

    async setAsignaturas() {
        let asignaturasByEvaluacionTipo = await this.store
            .pipe(
                select(selectAsignaturasByEvaluacionTipo),
                filter(x => !!x),
                first()
            )
            .toPromise()
        this.lista_asignaturas = asignaturasByEvaluacionTipo[this.tipoEvaluacion || config.plataforma.evaluacionDefault]
    }
}
