import { Component, Input, Directive } from "@angular/core"

/*#################################

Barra con el título que va en la parte superior. Puede tener una imagen en la parte izquierda (pendiente).

#################################*/

@Component({
    templateUrl: "titlebar.component.html",
    selector: "titlebar",
    styleUrls: ["titlebar.component.scss"]
})
export class TitlebarComponent {
    constructor() {}
}

@Directive({ selector: "titlebar-titulo, titlebar-subtitulo" })
export class TitlebarComponentTags {}
