<ng-container *ngIf="!saved">
    <form *ngIf="form" focusInvalidInput novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuarioRegistro['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuarioRegistro['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_materno']"
                    [(value)]="usuarioRegistro['apellido_materno']"
                    [key]="'apellido_materno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuarioRegistro['email']"
                    [key]="'email'"
                ></form-input>
            </div>
            <div class="col-md-6">
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuarioRegistro['password']"
                    [key]="'password'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuarioRegistro['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuarioRegistro['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                    class="condiciones"
                ></form-input>
                <br />
                <div class="center-captcha">
                    <re-captcha
                        *ngIf="captchaRequired"
                        (resolved)="resolved($event)"
                        [siteKey]="captchaSiteKey"
                    ></re-captcha>
                    <error-messages *ngIf="captchaRequired" [isExpanded]="!!errorCaptcha" containerId="captcha-error">
                        <ng-container *errorMessage>{{ errorCaptcha }}</ng-container>
                    </error-messages>
                </div>
                <br />

                <button (click)="save()" type="submit" class="btn btn-default btn-block submit-btn">Regístrate</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>
