<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <form *ngIf="usuarioAvatarEdit" novalidate [formGroup]="formAvatar">
                <cui-section-headline>Foto de perfil</cui-section-headline>
                <div class="avatar-edit-container">
                    <cui-button class="edit-avatar-btn" type="button" (click)="toggleAvatarEdit($event)">
                        Cambiar imagen
                    </cui-button>
                    <div *ngIf="usuarioAvatarEdit" class="avatar-container">
                        <loading-layout #loadingLayoutAvatar [opaque]="true" [loading]="false">
                            <img
                                class="avatar"
                                alt="avatar"
                                *ngIf="usuarioAvatarEdit.avatar"
                                [src]="usuario.avatar | trustedurl"
                                (load)="onLoadProfileImage()"
                            />
                            <img
                                class="avatar"
                                alt="no avatar"
                                *ngIf="!usuarioAvatarEdit.avatar"
                                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/default-profile.png"
                                (load)="onLoadProfileImage()"
                            />
                        </loading-layout>
                    </div>
                    <div id="avatarInput" [class.show-file-name]="usuarioAvatarEdit.file">
                        <form-input
                            [form]="formAvatar"
                            [params]="paramsAvatar['file']"
                            [(value)]="usuarioAvatarEdit['file']"
                            [key]="'file'"
                            [xhr_upload]="s3Service.xhr_upload"
                        ></form-input>
                        <div class="avatar-buttons clearfix">
                            <cui-button
                                type="button"
                                class="pull-right"
                                *ngIf="usuarioAvatarEdit.file"
                                (click)="saveProfileImage()"
                            >
                                Aceptar
                            </cui-button>
                            <cui-button
                                buttonType="cancel"
                                type="button"
                                class="pull-right"
                                *ngIf="usuarioAvatarEdit.file"
                                (click)="cancelNewAvatar()"
                            >
                                Cancelar
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="usuarioEdit" novalidate [formGroup]="form">
                <cui-section-headline>Información personal</cui-section-headline>

                <div class="user-info-container">
                    <cui-button
                        class="edit-user-info-btn"
                        [buttonType]="edit_user_info ? 'cancel' : 'primary'"
                        type="button"
                        (click)="toggleUserInfo($event)"
                    >
                        <span *ngIf="!edit_user_info">Editar</span>
                        <span *ngIf="edit_user_info">Cancelar</span>
                    </cui-button>
                    <div class="user-info" [class.hide]="edit_user_info">
                        <label>{{ params.nombre.label }}:</label>
                        <p>{{ usuario.nombreCompleto() }}</p>
                        <label>{{ params.email.label }}:</label>
                        <p>{{ usuario?.email }}</p>
                        <label>{{ params.fecha_nacimiento.label }}:</label>
                        <p>{{ usuario?.fecha_nacimiento ? "****" : "No definida" }}</p>
                    </div>
                    <!--Edicion-->
                    <div class="user-edit" [class.show]="edit_user_info">
                        <form-input
                            [form]="form"
                            [params]="params['id']"
                            [(value)]="usuarioEdit['id']"
                            [key]="'id'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['nombre']"
                            [(value)]="usuarioEdit['nombre']"
                            [key]="'nombre'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['apellido_paterno']"
                            [(value)]="usuarioEdit['apellido_paterno']"
                            [key]="'apellido_paterno'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['apellido_materno']"
                            [(value)]="usuarioEdit['apellido_materno']"
                            [key]="'apellido_materno'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['email']"
                            [(value)]="usuarioEdit['email']"
                            [key]="'email'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['fecha_nacimiento']"
                            [(value)]="usuarioEdit['fecha_nacimiento']"
                            [key]="'fecha_nacimiento'"
                        ></form-input>
                        <div class="clearfix user-edit-buttons">
                            <cui-button (click)="save()" type="button" class="btn_style pull-right">Guardar</cui-button>
                            <cui-button
                                buttonType="cancel"
                                (click)="clear()"
                                type="button"
                                class="btn_cancel pull-right"
                            >
                                Deshacer
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
            <ng-container *ngIf="usuario_paa">
                <cui-section-headline>Información PAA</cui-section-headline>
                <div class="user-info-container">
                    <cui-button
                        class="edit-user-info-btn"
                        [buttonType]="editingPaa ? 'cancel' : 'primary'"
                        type="button"
                        (click)="editingPaa = !editingPaa"
                    >
                        <span *ngIf="!editingPaa">Editar</span>
                        <span *ngIf="editingPaa">Cancelar</span>
                    </cui-button>
                    <div class="user-info" *ngIf="!editingPaa">
                        <label>PAA tomada:</label>
                        <p>{{ usuario.usuario_paa.paa_tomada ? "Sí" : "No" }}</p>
                        <ng-container *ngIf="showAllFormPaa">
                            <label>Año:</label>
                            <p>{{ usuario.usuario_paa.year }}</p>
                            <label>Lugar:</label>
                            <p>{{ usuario.usuario_paa.ubicacion }}</p>
                            <label>Puntaje matemáticas:</label>
                            <p>{{ usuario.usuario_paa.resultado_matematicas }}</p>
                            <label>Puntaje lectura y redacción:</label>
                            <p>{{ usuario.usuario_paa.resultado_lectura_redaccion }}</p>
                            <label>Puntaje inglés:</label>
                            <p>{{ usuario.usuario_paa.resultado_ingles }}</p>
                        </ng-container>
                    </div>

                    <div *ngIf="editingPaa" class="paa-form">
                        <form-input
                            [form]="paaForm"
                            [params]="paaParams['paa_tomada']"
                            [(value)]="usuario_paa['paa_tomada']"
                            [key]="'paa_tomada'"
                            (change)="onChangePaaTomada($any($event.target).value)"
                        ></form-input>
                        <div *ngIf="showAllFormPaa">
                            <form-input
                                [form]="paaForm"
                                [params]="paaParams['year']"
                                [(value)]="usuario_paa['year']"
                                [key]="'year'"
                            ></form-input>
                            <form-input
                                [form]="paaForm"
                                [params]="paaParams['ubicacion']"
                                [(value)]="usuario_paa['ubicacion']"
                                [key]="'ubicacion'"
                            ></form-input>
                            <label>¿Qué puntaje obtuviste?</label>
                            <form-input
                                [form]="paaForm"
                                [params]="paaParams['resultado_matematicas']"
                                [(value)]="usuario_paa['resultado_matematicas']"
                                [key]="'resultado_matematicas'"
                            ></form-input>
                            <form-input
                                [form]="paaForm"
                                [params]="paaParams['resultado_lectura_redaccion']"
                                [(value)]="usuario_paa['resultado_lectura_redaccion']"
                                [key]="'resultado_lectura_redaccion'"
                            ></form-input>
                            <form-input
                                [form]="paaForm"
                                [params]="paaParams['resultado_ingles']"
                                [(value)]="usuario_paa['resultado_ingles']"
                                [key]="'resultado_ingles'"
                            ></form-input>
                        </div>
                        <button (click)="enviarPaa()" type="button" class="btn btn-default btn-block submit-btn">
                            Guardar
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-6 col-sm-12">
            <cui-section-headline>Opciones</cui-section-headline>
            <cui-button *ngIf="!edit_password" type="button" (click)="showEditPassword()">
                Cambiar contraseña
            </cui-button>
            <cui-button (click)="deleteModal.buttonPressed()" buttonType="danger" class="pull-right">
                <fa name="times"></fa>
                <span>&nbsp;Eliminar cuenta</span>
            </cui-button>
            <div *ngIf="edit_password">
                <div class="edit-pass">
                    <cui-button
                        buttonType="cancel"
                        class="btn_cancel edit-user-info-btn"
                        type="button"
                        (click)="showEditPassword()"
                    >
                        Cancelar
                    </cui-button>
                    <usuario-edit-password></usuario-edit-password>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
<generic-modal #deleteModal>
    <modal-titulo>Eliminar cuenta</modal-titulo>
    <modal-contenido>
        <p class="alert alert-danger">Cuidado, esta acción es irreversible.</p>
        <div class="form-group">
            <label>
                Escribe
                <strong>"ELIMINAR CUENTA"</strong>
                para proceder
            </label>
            <input [(ngModel)]="deleteInput" type="text" class="form-control" />
            <br />
            <button
                (click)="deleteModal.close(); eliminarCuenta()"
                [disabled]="deleteInput !== 'ELIMINAR CUENTA'"
                class="btn btn-danger"
            >
                Eliminar cuenta
            </button>
        </div>
    </modal-contenido>
</generic-modal>

<generic-modal #cuentaEliminadaModal>
    <modal-titulo>Cuenta eliminada</modal-titulo>
    <modal-contenido>
        <p class="alert alert-info">Tu cuenta ha sido eliminada con éxito.</p>
    </modal-contenido>
</generic-modal>
