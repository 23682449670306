import { RestrictedLayoutComponent } from "../layouts/restricted_layout/restricted_layout.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    RealizarEnsayoComponent,
    EnsayoInstanciasComponent,
    EnsayosMensualesComponent,
    EvaluacionesCompartidasComponent,
    GenerarEnsayoComponent,
    ImprimirEnsayoComponent,
    ResultadosEnsayoComponent,
    GenerarEnsayoSimpleComponent,
    RealizarEvaluacionInteractivaComponent,
    RealizarEvaluacionMultipleComponent,
    ResultadosEvaluacionMultipleComponent,
    EstadisticasAlumnoComponent,
    EvaluacionesCorregirComponent
} from "@puntaje/puntaje/core"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomeEnsayosComponent } from "./home_ensayos.component"
import { HomePruebasComponent } from "./home_pruebas.component"

import { ProgresoCbComponent } from "../estadisticas/progreso_cb.component"

export const routes: Routes = [
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [AuthGuard],
                data: {
                    theme: "theme-cb",
                    hideNombre: true,
                    hideCompartidoPor: true
                }
            }
        ]
    },
    {
        path: "evaluacion_instancias/subir",
        component: RestrictedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionesCorregirComponent,
                data: { typePlatform: "alumnoTwo", perfil: "alumno", titulo: "Subir resultados de Evaluaciones" },
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "ensayos/generar_ensayo",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoSimpleComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: RealizarEnsayoComponent,
                canActivate: [AuthGuard, TokenGuard],
                data: {
                    theme: "theme-cb",
                    enableBtnMarkAsUncertain: true,
                    enablePreguntasId: false
                }
            }
        ]
    },
    {
        path: "ensayos/:id/imprimir",
        component: RestrictedLayoutComponent,
        children: [{ path: "", component: ImprimirEnsayoComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: ResultadosEnsayoComponent,
                canActivate: [AuthGuard],
                data: {
                    enableDudas: false,
                    enableReporte: false,
                    enableDesempeno: true,
                    volverARealizar: false,
                    realizaNuevo: true
                }
            }
        ]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/realizar_interactiva",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: RealizarEvaluacionInteractivaComponent,
                canActivate: [AuthGuard],
                data: {
                    theme: "theme-cb",
                    enableBtnMarkAsUncertain: true,
                    enablePreguntasId: false
                }
            }
        ]
    },
    {
        path: "evaluaciones/:id/realizar_multiple",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: RealizarEvaluacionMultipleComponent,
                canActivate: [AuthGuard],
                canDeactivate: ["canExitEvaluacion"]
            }
        ]
    },
    {
        path: "evaluaciones/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "ensayos_breves",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayosMensualesComponent, canActivate: [AuthGuard] }]
    },
    //Calugas
    { path: "ensayos/estadisticas", component: EstadisticasAlumnoComponent, canActivate: [AuthGuard] },
    { path: "ensayos/mi_progreso", component: ProgresoCbComponent, canActivate: [AuthGuard] },
    {
        path: "ensayos/resultados",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [AuthGuard],
                data: {
                    theme: "theme-cb",
                    hideNombre: true,
                    hideCompartidoPor: true
                }
            }
        ]
    },
    {
        path: "ensayos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeEnsayosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "pruebas/generar_prueba/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    { path: "pruebas/estadisticas", component: EstadisticasAlumnoComponent, canActivate: [AuthGuard] },
    { path: "pruebas/mi_progreso", component: ProgresoCbComponent, canActivate: [AuthGuard] },
    {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [AuthGuard],
                data: {
                    theme: "theme-cb",
                    hideNombre: true,
                    hideCompartidoPor: true
                }
            }
        ]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluaciones",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeEnsayosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluacion_multiple_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEvaluacionMultipleComponent, canActivate: [AuthGuard, TokenGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
