/** @format */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"

import { AlumnosLayoutsModule } from "./layouts/alumnos_layouts.module"
import { AlumnosLandingModule } from "./landing/alumnos_landing.module"
import { AlumnosUsuariosModule } from "./usuarios/alumnos_usuarios.module"
import { AlumnosEnsayosModule } from "./ensayos/alumnos_ensayos.module"
import { AlumnosMaterialesModule } from "./materiales/alumnos_materiales.module"
import { AlumnosEstadisticasModule } from "./estadisticas/alumnos_estadisticas.module"
import { AlumnosPlanPersonalesModule } from "./plan_personales/alumnos_plan_personales.module"
import { config } from "../config/config"
import { environment } from "../environments/environment"
import { AppComponent, getProviders, PuntajeCoreModule } from "@puntaje/puntaje/core"
import { AlumnosPlanEstudiosModule } from "./plan_estudios/alumnos_plan_estudios.module"

@NgModule({
    imports: [
        AlumnosLayoutsModule.forRoot(),
        AlumnosLandingModule.forRoot(),
        AlumnosUsuariosModule,
        AlumnosEnsayosModule,
        AlumnosMaterialesModule,
        AlumnosEstadisticasModule,
        AlumnosPlanPersonalesModule,
        AlumnosPlanEstudiosModule,
        PuntajeCoreModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: getProviders(config, environment)
})
export class AppModule { }
