<generic-modal [buttonClicked]="openModal" [id]="'noticias-modal'">
    <modal-titulo>
        <div class="modal_title">
            <div class="modal_title_inner">
                <div class="modal_title_icon">
                    <div class="icon-container">
                        <ogr-icon name="{{ config.plataforma.iconSet.noticiasShowcase }}" class="icon"></ogr-icon>
                    </div>
                </div>
                <div class="modal_title_text">Noticias</div>
            </div>
        </div>
    </modal-titulo>
    <modal-contenido class="modal-contenido">
        <div class="modal-contenido-wrap">
            <carousel
                [itemsPerSlide]="1"
                [singleSlideOffset]="true"
                [noWrap]="true"
                [interval]="$any(false)"
                [startFromIndex]="0"
                [showIndicators]="true"
                [class.izq-hidden]="noShowIzq"
                [class.derec-hidden]="noShowDerec"
                (slideRangeChange)="showControler($event)"
                (activeSlideChange)="saveInstancia($event)"
                class="carousel-news"
            >
                <ng-container *ngFor="let noticia of noticias">
                    <slide *ngIf="true">
                        <ver-noticia [noticia]="noticia" [showTitle]="true" class="ver-noticia"></ver-noticia>
                    </slide>
                </ng-container>
            </carousel>
        </div>
    </modal-contenido>
</generic-modal>
