import { Component, Directive, ViewChild, AfterContentInit, Input } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"

/*#################################

restricted-layout-toolnavbar
restricted-layout-titulo
restricted-layout-subtitulo
restricted-layout-contenido
restricted-layout-tabs
restricted-layout-topside

#################################*/

@Component({
    templateUrl: "restricted_layout.component.html",
    selector: "restricted-layout",
    styleUrls: ["restricted_layout.scss"]
})
export class RestrictedLayoutComponent implements AfterContentInit {
    @ViewChild("restrictedLayoutTabs", { static: true }) restrictedLayoutTabs
    @ViewChild("restrictedLayoutContenido", { static: true }) restrictedLayoutContenido
    @ViewChild("restrictedLayoutTopside", { static: true }) restrictedLayoutTopside
    showTabs: boolean = false
    showContenido: boolean = false
    showTopside: boolean = false
    @Input() freeContentStyle: boolean = false

    @Input() subtitle: string = ""

    constructor(public authService: AuthService) {}

    ngAfterContentInit() {
        this.showTabs = this.restrictedLayoutTabs.nativeElement.children.length > 0
        this.showContenido = this.restrictedLayoutContenido.nativeElement.children.length > 0
        this.showTopside =
            this.restrictedLayoutTopside && this.restrictedLayoutTopside.nativeElement.children.length > 0
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }
}

@Directive({
    selector:
        "restricted-layout-titulo, restricted-layout-subtitulo, restricted-layout-contenido, restricted-layout-tabs, restricted-layout-toolnavbar, restricted-layout-topside"
})
export class RestrictedLayoutComponentTags {}
