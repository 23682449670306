import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { AuthService, TitleService } from "@puntaje/shared/core"
import { GrupoUsuarios, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home_ensayos.component.html",
    styleUrls: ["home_ensayos.component.scss"]
})
export class HomeEnsayosComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []
    disableMenuItem: boolean = false
    config: typeof config = config

    constructor(
        protected store: Store<State>,
        protected grupoUsuariosService: GrupoUsuarios,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService,
        protected titleService: TitleService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.getGrupoUsuarios()
        this.titleService.setTitle("Evaluaciones")
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/ensayos/generar_ensayo/" + asignaturasByEvaluacionTipo["ensayo"][0].id,
                params: { tipo_instrumento: "ensayo" },
                label: "Realizar Ensayo PSU",
                text: "Practica realizando ensayos similares a la PSU.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/ensayos/resultados/",
                params: { tipo_instrumento: "ensayo", asignatura_id: 1 },
                label: "Historial",
                text: "Encuentra aquí la lista de todos los ensayos que has realizado.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/evaluaciones/compartidas/",
                params: { tipo_instrumento: "ensayo", asignatura_id: 1 },
                label: "Historial de ensayos compartidos",
                text: "Encuentra aquí la lista de todos los ensayos que te han compartido.",
                linkText: "Ver historial",
                icon: "guia",
                locked: this.disableMenuItem,
                lockedText:
                    "Si tu colegio tiene contratado los servicios de " +
                    this.config.plataforma.info.companyName +
                    ", en esta sección encontrarás la lista de todos los ensayos que te han compartido tus profesores."
            },
            {
                route: "/ensayos/mi_progreso/",
                params: { tipo_instrumento: "ensayo" },
                label: "Mi Progreso",
                text: "Observa la evolución en el tiempo de tus resultados obtenidos.",
                linkText: "Ver mi progreso",
                icon: "estadistica-o"
            },
            {
                route: "/ensayos/estadisticas/",
                params: { tipo_instrumento: "ensayo" },
                label: "Estadísticas PSU",
                text: "Encuentra aquí estadísticas generadas a partir de tus resultados en ensayos PSU.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }

    getGrupoUsuarios() {
        let usuario = this.authService.getUserData()
        let params = {
            establecimiento_id_not_nil: 1,
            propios: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 0,
                usuario_id: usuario.id
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.disableMenuItem = grupoUsuarios.length <= 0
            this.setMenuItems()
        })
    }
}
