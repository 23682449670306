import { CUILayoutsModule } from "@puntaje/shared/cui"
import { PuntajeEstadisticasModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"

import { estadisticasRouting } from "./alumnos_estadisticas.routing"
import { UtilModule } from "@puntaje/shared/core"

import { ProgresoCbComponent } from "./progreso_cb.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlumnosLayoutsModule,
        EstadisticasModule,
        estadisticasRouting,
        EnsayosModule,
        UtilModule,
        PuntajeEstadisticasModule,
        CUILayoutsModule
    ],
    declarations: [ProgresoCbComponent],
    exports: []
})
export class AlumnosEstadisticasModule {}
