import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class UsuarioPaaForm extends BaseForm {
    public static get formParams(): any {
        const year = new Date().getFullYear()

        let si = { id: true, toString: () => "Sí" }
        let no = { id: false, toString: () => "No" }

        return {
            id: { label: "Id", type: "hidden", visible: false },
            paa_tomada: {
                label: "¿Has tomado la PAA?",
                type: "select",
                options: [si, no],
                visible: true,
                showNameSelect: false
            },
            year: {
                label: "¿Qué año la tomaste?",
                type: "number",
                visible: true,
                validations: [Validators.max(year), Validators.min(1960)]
            },
            ubicacion: { label: "¿Dónde la tomaste?", type: "text", visible: true },
            resultado_matematicas: {
                label: "Matemáticas",
                type: "number",
                visible: true,
                validations: [Validators.max(800), Validators.min(200)]
            },
            resultado_lectura_redaccion: {
                label: "Lectura y Redacción",
                type: "number",
                visible: true,
                validations: [Validators.max(800), Validators.min(200)]
            },
            resultado_ingles: {
                label: "Inglés",
                type: "number",
                visible: true,
                validations: [Validators.max(800), Validators.min(200)]
            }
        }
    }
}
