import { Component, HostBinding, OnInit } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "evaluaciones-instancias-cb-showcasebox",
    templateUrl: "evaluaciones_instancias_cb_showcasebox.component.html",
    styleUrls: ["evaluaciones_instancias_cb_showcasebox.component.scss"]
})
export class EvaluacionesInstanciasCBShowcaseBoxComponent implements OnInit {
    usuario_id: string
    @HostBinding("class") innerClass = "showcasebox_style_1"

    constructor(protected authService: AuthService) {
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id
    }

    ngOnInit() {}

    /*showRecent() {
		//this.loadingLayout.standby();
		let params: any = {
			page: 1,
			per: 3,
			mios: 1,
			include: "evaluacion:evaluacion_tipo"
		};

		if(this.evaluacionTipo) params.evaluacion_tipo = { evaluacion_tipo: this.evaluacionTipo };

		this.evaluacionInstanciasService.where(params).then((eis: EvaluacionInstancia[], total: number) => {
			this.evaluacionInstancias = eis;
			this.loadingLayout.ready();
		});
	}*/
}
