import {
    MaterialAppsComponent,
    MaterialUDDComponent,
    RecursosInteractivosComponent,
    MaterialComponent,
    MaterialesSIMCEComponent,
    MaterialesSaberComponent,
    MaterialesV1Component,
    BibliotecaComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import { HomeBibliotecaComponent } from "./home_biblioteca.component"
import { VideotecaComponent } from "./videoteca.component"

export const materialesRoutes: Routes = [
    {
        path: "materiales",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialesV1Component, canActivate: [AuthGuard] }]
    },
    {
        path: "materiales/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialComponent,
                data: { enableDudas: false, enableFavorito: false },
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "materiales_simce",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialesSIMCEComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "materiales_saber",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialesSaberComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "recursos_interactivos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RecursosInteractivosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "material_udd",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialUDDComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "material_app",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialAppsComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "biblioteca",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BibliotecaComponent, data: { openOnInit: false }, canActivate: [AuthGuard] }]
    },
    {
        path: "bibliotecas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeBibliotecaComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "videoteca",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: VideotecaComponent, canActivate: [AuthGuard] }]
    }
]

export const materialesRouting = RouterModule.forChild(materialesRoutes)
