<div class="clearfix" (window:resize)="onResize($event)">
    <menu-asignaturas-horizontal
        [tipoEvaluacion]="evaluacionTipo"
        [enableResponsive]="enableResponsive"
        (asignaturaObjChanged)="onAsignaturaChange($event)"
    ></menu-asignaturas-horizontal>
    <loading-layout #loadingLayout>
        <div *ngIf="!loadingLayout.loading">
            <br />
            <grafico-desempeno-evolutivo
                *ngIf="loadingLayout"
                [enableDemoMode]="false"
                [tipoEvaluacion]="evaluacionTipo"
                [dataSets]="dataSets"
                [tituloPrincipal]="'Evolución de tus resultados'"
                titulo="Tu progreso en las pruebas de práctica"
                descripcion="Evolución de tus resultados entre las diferentes pruebas de práctica"
                class="no-top-margin"
            ></grafico-desempeno-evolutivo>
            <hr />

            <div class="form-group">
                <label>
                    Seleccione prueba de práctica
                    <select
                        class="form-control"
                        [(ngModel)]="evaluacionMultiple"
                        (ngModelChange)="onEvaluacionMultipleSelected()"
                    >
                        <option *ngFor="let evaluacionMultiple of evaluacionMultiples" [ngValue]="evaluacionMultiple">
                            {{ evaluacionMultiple.evaluacion_multiple }}
                        </option>
                    </select>
                </label>
            </div>

            <grafico-desempeno-evolutivo
                *ngIf="loadingLayout"
                [enableDemoMode]="false"
                [tipoEvaluacion]="evaluacionTipo"
                [dataSets]="dataSetsIntentos"
                [tituloPrincipal]="'Evolución de tus resultados para cada prueba de práctica'"
                titulo="Tu progreso en prueba de práctica seleccionada"
                descripcion="Evolución de tus resultados de prueba de práctica seleccionada"
                class="no-top-margin"
            ></grafico-desempeno-evolutivo>
        </div>
    </loading-layout>
</div>
