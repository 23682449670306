<el-titulo>
    <ng-template [ngIf]="evaluacionMultipleInstancia"></ng-template>
</el-titulo>
<el-subtitulo>
    <ng-template [ngIf]="evaluacionMultipleInstancia"></ng-template>
</el-subtitulo>

<el-contenido-lateral>
    <evaluacion-instancia-multiple-resumen [evaluacionMultipleInstanciaId]="evaluacionMultipleInstanciaId"
        (evaluacionMultipleInstanciaReady)="onEvaluacionMultipleInstanciaReady($event)">
    </evaluacion-instancia-multiple-resumen>
</el-contenido-lateral>

<ng-container *ngIf="evaluacionMultipleInstancia">
    <cui-tabs justifyContent="flex-start">
        <cui-tab title="Detalle">
            <evaluacion-instancia-multiple-detalle-clasificaciones
                [evaluacionMultipleInstancia]="evaluacionMultipleInstancia">
            </evaluacion-instancia-multiple-detalle-clasificaciones>
        </cui-tab>
        <cui-tab *ngFor="let evaluacionInstancia of evaluacionMultipleInstancia.evaluacion_instancias; let i = index"
            title="Parte {{ i + 1 }}" (select)="setCurrentEvaluacion(evaluacionInstancia)">
            <respuestas-ensayo [enableDudas]="false" [enableReporte]="false" [enableDesempeno]="false"
                [evaluacionInstanciaId]="evaluacionInstancia.id"></respuestas-ensayo>
        </cui-tab>
    </cui-tabs>
</ng-container>