<ll-toolnavbar>
    <usuario-toolnavbar [usuario]="usuario" [displayButtons]="'all new edit'"></usuario-toolnavbar>
</ll-toolnavbar>
<ll-titulo>{{ usuario?.nombreCompleto() }}</ll-titulo>
<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <cui-button-link [routerLinkValue]="['/usuarios/editar']" size="small" class="pull-right edit-btn"
            *ngIf="!isPrivate">
            <fa name="edit" class="left-icon"></fa>
            <span>Editar cuenta</span>
        </cui-button-link>
        <usuario [usuario]="usuario" [enableShowEstablecimientoData]="false" [enableShowExtraData]="true">
            <usuario-paa-show [usuarioId]="usuarioId"></usuario-paa-show>
        </usuario>
    </div>
</loading-layout>