import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType,
    CarrerasConf,
    MensajesLoginType,
    RegistroConf,
    ComponentsMap,
    GenerarConf,
    PlanificacionesConf
} from "@puntaje/shared/core"

export class CbConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones"]
    }

    app: AppType = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoAltSmall: "assets/img/logos/logoNavBar.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "tec",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            sharedLayout: {
                backgroundImage: ""
            }
        }
    }

    amazon: AmazonType = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: ""
    }

    api: ApiType = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    }

    asignaturas: AsignaturaType[] = [
        {
            id: null,
            asignatura: "Lectura",
            icono: "lectura-paa",
            clase: "lectura",
            abreviacion: "Lectura",
            asignaturaPlataforma: "Lectura",
            evaluacionTipo: ["paa"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Redacción",
            icono: "redaccion-paa",
            clase: "redaccion",
            abreviacion: "Redacción",
            asignaturaPlataforma: "Redacción",
            evaluacionTipo: ["paa"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematicas-paa",
            clase: "matematica",
            abreviacion: "Matemáticas",
            asignaturaPlataforma: "Matemáticas",
            evaluacionTipo: ["paa"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Inglés",
            icono: "ingles-paa",
            clase: "ingles",
            abreviacion: "Inglés",
            asignaturaPlataforma: "Inglés",
            evaluacionTipo: ["paa"],
            materialVisible: true
        }
    ]

    plataforma: PlataformaType = {
        usuarioPaa: true,
        name: "College Board",
        id: null,
        pais: "chile",
        locale: "es",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        disableWarningOmitidas: true,
        nombreColumnaResultados: "Núm ejercicios",
        styleTheme: "Theme-CB",
        evaluacionResponder: "secuencial",
        info: {
            social: {
                facebook: "https://www.facebook.com/thecollegeboard?navId=gf-sm-fb&navId=gf-sm-fb",
                twitter: "https://twitter.com/collegeboard?navId=gf-sm-tw&navId=gf-sm-tw",
                youtube: "https://www.youtube.com/user/collegeboard?navId=gf-sm-yt&navId=gf-sm-yt",
                instagram: "https://www.instagram.com/collegeboard/?navId=gf-sm-in&navId=gf-sm-in",
                linkedin: "https://www.linkedin.com/company/the-college-board?navId=gf-sm-li&navId=gf-sm-li"
            },
            soporte: {
                mail: "paa.contacto@aprendolibre.com",
                telefono: "placeholder-supportPhone"
            },
            contacto: {
                telefono: "placeholder-contactPhone",
                mail: "placeholder-contactMail"
            },
            companyName: "AprendoLibre.com – Práctica Oficial para la PAA",
            companySite: "https://www.collegeboard.org/"
        },
        evaluacionDefault: "paa",
        evaluacionTipoAdaptiveDefault: "diagnostico paa",
        resumenGeneral: {
            curriculum: "simce",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["paa"],
        añoInicial: 2018,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "paa",
        canTextHighlight: true,
        orderFiltroClasificaciones: true,
        disableCalificacionPlanPersonalSession: true,
        hidePersonalizarByAsignatura: ["Redacción"],
        disableSentimentMaterial: true,
        planPersonalesDisableNivel: true,
        title: {
            prefix: "Practica para la PAA",
            suffix: "College Board"
        },
        logoAlt: "Aprendo Libre"
    }

    evaluaciones: EvaluacionTipoMap = {
        paa: {
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            alias: "paa",
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: ["eje temático paa", "sub eje tematico"],
                groupBy: ["eje temático paa,sub eje temático paa", "nivel cognitivo"],
                clasificacionTiposTablaEspecificaciones: [],
                configuration: [
                    {
                        clasificacionTipo: "eje temático paa",
                        name: "Área",
                        htmlId: "area",
                        hasHijos: true
                    }
                ],
                desempenoEvaluacion: "eje temático paa",
                desempenoEvaluacionLabel: "Área"
            },
            clasificacionTipoResultados: "eje temático paa",
            clasificacionMaterial: {
                clasificacion: "eje temático paa",
                label: "area"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático paa",
                    compuesto: false,
                    alumnos: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "sub eje temático paa",
                    compuesto: true,
                    alumnos: true,
                    profesores: true,
                    informeAnual: false
                }
            ],
            cursos: [],
            cursosMaterial: [],
            estrellas: [
                { min: 150, max: 266 },
                { min: 267, max: 383 },
                { min: 384, max: 500 },
                { min: 501, max: 617 },
                { min: 618, max: 734 },
                { min: 735, max: 850 }
            ],
            nivelTipoInstrumentoInformeFinal: [],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases PSU",
            cursoTipo: null
        },
        "diagnostico paa": {
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            cantidadPreguntas: 4,
            cursoTipo: null
        }
    }

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email",
        invalidLoginId: "Debes incluir un correo electrónico válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2: ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús".',
        invalidLoginInactiveEstablishments: "",
        invalidLoginInactiveEstablishmentsRedirectText: ""
    } as MensajesLoginType

    carreras: CarrerasConf

    componentsInSubmodule: ComponentsMap

    registro: RegistroConf

    aditionalClass: false

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: false,
        buscador: true
    } as GenerarConf

    planificaciones: PlanificacionesConf
}
