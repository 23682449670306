import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { EvaluacionMultipleInstancia, Estadisticas } from "@puntaje/puntaje/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { ClasificacionTipos, Asignatura } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Subscription } from "rxjs"
import { SumTotalPercentage } from "@puntaje/shared/core"

@Component({
    selector: "evaluacion-instancia-multiple-detalle-clasificaciones",
    templateUrl: "./evaluacion-instancia-multiple-detalle-clasificaciones.component.html",
    styleUrls: ["./evaluacion-instancia-multiple-detalle-clasificaciones.component.scss"]
})
export class EvaluacionInstanciaMultipleDetalleClasificacionesComponent implements OnInit {
    @Input() evaluacionMultipleInstancia: EvaluacionMultipleInstancia
    @Input() enableToggleRespuestas: boolean = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    estadisticaClasificacionesByAsignaturaId: { [asignaturaId: number]: any }

    sub: Subscription
    listaAsignaturas: Asignatura[] = []
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x),
        first()
    )

    evaluacionTipo: string
    config: typeof config
    readingAndWritingTotal: number

    constructor(
        protected estadisticasService: Estadisticas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.config = config
        this.evaluacionTipo = this.evaluacionMultipleInstancia.evaluacion_multiple.evaluacion_tipo.evaluacion_tipo
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.listaAsignaturas = asignaturasByEvaluacionTipo[this.evaluacionTipo]
            this.getData()
        })
    }

    async getData() {
        const clasificacionTipoNombre = config.evaluaciones[this.evaluacionTipo].clasificacionTipoResultados || "area"
        const clasificacionTipos = await this.clasificacionTiposService.where({
            clasificacion_tipo: { clasificacion_tipo: clasificacionTipoNombre }
        })
        const clasificacionTipo = clasificacionTipos[0]

        const params = {
            collection: "EstadisticaInstanciaClasificacion",
            estadistica: {
                evaluacion_instancia_id: this.evaluacionMultipleInstancia.evaluacion_instancias.map(ei => ei.id)
            }
        }

        const estadisticas = await this.estadisticasService.wherePost(params)

        this.estadisticaClasificacionesByAsignaturaId = estadisticas
            .map(e => e.estadistica_clasificaciones)
            .flat()
            .filter(ec => ec.clasificacion.clasificacion_tipo_id == clasificacionTipo.id)
            .reduce((acc, ec) => {
                acc[ec.clasificacion.asignatura_id] = acc[ec.clasificacion.asignatura_id] || []
                let estadisticaClasificacion = acc[ec.clasificacion.asignatura_id].find(
                    eca => eca.clasificacion.id == ec.clasificacion.id
                )

                if (estadisticaClasificacion) {
                    estadisticaClasificacion.correctas += ec.correctas
                    estadisticaClasificacion.incorrectas += ec.incorrectas
                    estadisticaClasificacion.omitidas += ec.omitidas
                } else {
                    acc[ec.clasificacion.asignatura_id].push(ec)

                    estadisticaClasificacion = ec
                }

                const total =
                    estadisticaClasificacion.correctas +
                    estadisticaClasificacion.incorrectas +
                    estadisticaClasificacion.omitidas
                estadisticaClasificacion.porcentaje = ((1.0 * estadisticaClasificacion.correctas) / total) * 100

                return acc
            }, {})

        // Ver si se saca en el futuro
        if (config.plataforma.name == "College Board") {
            const redaccion = this.listaAsignaturas.find(asignatura => asignatura.asignatura == "Redacción")
            const lectura = this.listaAsignaturas.find(asignatura => asignatura.asignatura == "Lectura")
            if (
                redaccion &&
                lectura &&
                this.estadisticaClasificacionesByAsignaturaId[lectura.id] &&
                this.estadisticaClasificacionesByAsignaturaId[redaccion.id]
            ) {
                let sumTotalLectura = new SumTotalPercentage().transform(
                    this.estadisticaClasificacionesByAsignaturaId,
                    lectura.id
                )
                let sumTotalRedaccion = new SumTotalPercentage().transform(
                    this.estadisticaClasificacionesByAsignaturaId,
                    redaccion.id
                )

                this.readingAndWritingTotal = this.calcularCalificacion(
                    this.estadisticaClasificacionesByAsignaturaId[lectura.id].concat(
                        this.estadisticaClasificacionesByAsignaturaId[redaccion.id]
                    )
                ) //sumTotalLectura + sumTotalRedaccion
            }
        }

        this.loadingLayout.ready()
    }

    calcularCalificacion(arr: { correctas: number; omitidas: number; incorrectas: number }[]) {
        const correctas = arr.map(x => x.correctas).reduce((a, b) => a + b, 0)
        const total = arr.map(x => x.correctas + x.omitidas + x.incorrectas).reduce((a, b) => a + b, 0)

        return total === 0 ? 0 : (100.0 * correctas) / total
    }

    getCalificacionSimulacroSaberPro(asignaturaId: number): number {
        const instancia = this.evaluacionMultipleInstancia.evaluacion_instancias.find(
            ei => ei.evaluacion.instrumento.asignatura_id === asignaturaId
        )
        return instancia ? instancia.calificacion : null
    }
}
