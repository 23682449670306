import { EvaluationLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { combineLatest, Subscription } from "rxjs"
import {
    EvaluacionMultiples,
    EvaluacionMultiple,
    Evaluacion,
    EvaluacionInstancias,
    EvaluacionInstancia,
    EvaluacionMultipleInstancia,
    EvaluacionMultipleInstancias,
    EvaluacionTiempo
} from "@puntaje/puntaje/api-services"
import { EvaluacionSecuencialComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Instrucciones } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State, SetActual } from "@puntaje/puntaje/store"
import { SetSeeingIndex, SetRespuestasActual, ResetDudaPreguntas, SetItems } from "@puntaje/puntaje/store"
import { first } from "rxjs/operators"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./realizar_evaluacion_multiple.component.html",
    styleUrls: ["realizar_evaluacion_multiple.component.scss"]
})
export class RealizarEvaluacionMultipleComponent implements OnInit {
    sub: Subscription
    subData: Subscription
    evaluacionMultipleId: number
    evaluacion: Evaluacion
    evaluacionId: number
    evaluacionTiempo: EvaluacionTiempo
    showInstruccion: boolean = true
    showWait: boolean = false
    showTimeup: boolean = false
    hideProgreso: boolean = false
    loadClock: boolean = false
    evaluacionIndex: number = -1
    evaluaciones: Evaluacion[]
    completarButton: String = "continuar"
    enableBtnMarkAsUncertain: boolean = false
    canGoAway: boolean = false

    @ViewChild(EvaluacionSecuencialComponent, { static: false }) evaluacionSecuencial: EvaluacionSecuencialComponent
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected authService: AuthService,
        protected evaluacionMultipleInstanciasService: EvaluacionMultipleInstancias,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef,
        protected instruccionesService: Instrucciones,
        protected evaluationLayoutService: EvaluationLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.evaluaciones_multiples.realizar")
        this.loadingLayout.standby()
        this.store.dispatch(new SetActual({ actual: null }))
        this.store.dispatch(new SetRespuestasActual({ respuestasActual: [] }))
        this.store.dispatch(new SetSeeingIndex(0))
        this.sub = combineLatest([this.route.params, this.route.queryParams.pipe(first())]).subscribe(
            ([params, queryParams]) => {
                const startAtTheBeginnning = !!queryParams["from_start"]
                const usuarioId = this.authService.getUserData().id

                this.evaluacionMultipleId = +params["id"]

                this.evaluacionMultiplesService
                    .find(this.evaluacionMultipleId, { include: "evaluaciones" })
                    .then(async (evaluacionMultiple: EvaluacionMultiple) => {
                        const evaluacionInstancias = await this.getEvaluacionInstancias(evaluacionMultiple, usuarioId)
                        this.evaluaciones = evaluacionMultiple.evaluaciones

                        if (!startAtTheBeginnning) {
                            const filteredEvaluaciones = this.evaluaciones.filter(
                                e => !evaluacionInstancias.find(ei => ei.evaluacion_id == e.id)
                            )

                            const evaluacionMultipleDone = filteredEvaluaciones.length == 0
                            if (!evaluacionMultipleDone) {
                                this.evaluaciones = filteredEvaluaciones
                            }
                        }

                        this.showInstruccion = startAtTheBeginnning || evaluacionInstancias.length == 0
                        if (!this.showInstruccion) {
                            this.evaluacionIndex += 1
                            this.evaluacionId = this.evaluaciones[this.evaluacionIndex].id
                        } else {
                            this.resetStore()
                        }

                        if (this.evaluacionIndex == this.evaluaciones.length - 1) {
                            this.completarButton = "completar"
                        }

                        this.loadingLayout.ready()
                    })
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.enableBtnMarkAsUncertain = data.enableBtnMarkAsUncertain ?? this.enableBtnMarkAsUncertain
        })

        this.evaluationLayoutService.setOptions({ singleColumn: true, hideTitle: true })
    }

    onTimeup() {
        this.showTimeup = true
        this.hideProgreso = true
        setTimeout(() => {
            this.showTimeup = false
            this.evaluacionSecuencial.entregarEnsayo()
        }, 6000)
    }

    onReadyEvaluacion(evaluacion) {
        this.evaluacion = evaluacion
    }

    onLoadClockChange(loadClock) {
        this.loadClock = loadClock
    }

    onTiempoReady = function (ensayoTiempo) {
        this.evaluacionTiempo = ensayoTiempo
        this.cdr.detectChanges()
        this.evaluacionSecuencial.getRespuestasFromLocal()
        this.evaluacionSecuencial.displayPreguntas = true
    }

    onEvaluacionEntregada = evaluacionInstancia => {
        this.hideProgreso = false

        this.evaluacionId = null
        if (this.evaluaciones.length - 1 == this.evaluacionIndex) {
            this.canGoAway = true
            this.router.navigate([
                "/evaluacion_multiple_instancias",
                evaluacionInstancia.evaluacion_multiple_instancia.id
            ])
        } else {
            this.router.navigate([], { replaceUrl: true, queryParams: {} })

            this.showWait = true
        }

        this.resetStore()
    }

    next() {
        this.showInstruccion = this.showWait = this.loadClock = false
        this.evaluacionIndex++
        this.evaluacionId = this.evaluaciones[this.evaluacionIndex].id
        if (this.evaluacionIndex == this.evaluaciones.length - 1) {
            this.completarButton = "completar"
        }
    }

    resetStore() {
        this.store.dispatch(new SetActual({ actual: null }))
        this.store.dispatch(new SetItems({ items: [] }))
        this.store.dispatch(new SetRespuestasActual({ respuestasActual: [] }))
        this.store.dispatch(new ResetDudaPreguntas())
        this.store.dispatch(new SetSeeingIndex(0))
    }

    private async getEvaluacionInstancias(evaluacionMultiple: EvaluacionMultiple, usuarioId: number) {
        const evaluacionMultipleInstanciasParams = {
            search: 1,
            sort_by: "created_at",
            order: "desc",
            evaluacion_multiple_instancia: {
                evaluacion_multiple_id: evaluacionMultiple.id,
                oficial: 1,
                usuario_id: usuarioId
            },
            include: "[evaluacion_instancias]",
            methods: "[calificacion]"
        }

        const evaluacionMultipleInstancias = await this.evaluacionMultipleInstanciasService.where(
            evaluacionMultipleInstanciasParams
        )

        return evaluacionMultipleInstancias[0] ? evaluacionMultipleInstancias[0].evaluacion_instancias : []
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
