import { Component, OnInit, Input } from "@angular/core"
import { UsuariosPaa, UsuarioPaa } from "@puntaje/puntaje/api-services"

@Component({
    selector: "usuario-paa-show",
    templateUrl: "./usuario-paa-show.component.html",
    styleUrls: ["./usuario-paa-show.component.scss"]
})
export class UsuarioPaaShowComponent implements OnInit {
    @Input() usuarioId: number
    usuarioPaa: UsuarioPaa

    constructor(protected usuarioPaaServices: UsuariosPaa) {}

    ngOnInit() {
        let params = {
            usuario_paa: {
                usuario_id: this.usuarioId
            }
        }
        return this.usuarioPaaServices.wherePost(params).then((usuarioPaas: UsuarioPaa[]) => {
            this.usuarioPaa = usuarioPaas[0]
        })
    }
}
