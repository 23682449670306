import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura, Clasificacion, Clasificaciones, ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import {
    Estadisticas,
    EvaluacionInstancia,
    EvaluacionInstancias,
    GrupoRecursos,
    EvaluacionMultiples,
    EvaluacionMultiple
} from "@puntaje/puntaje/api-services"
import { EstadisticasEvolutivasService } from "./estadisticas_evolutivas.service"

@Component({
    selector: "grafico-mi-progreso-cb",
    templateUrl: "grafico_mi_progreso_cb.component.html",
    styleUrls: ["grafico_mi_progreso_cb.component.scss"]
})
export class GraficoMiProgresoCbComponent implements OnInit {
    dataSets: any
    dataSetsIntentos: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    enableResponsive: boolean = false
    @Input() evaluacionTipo: string

    asignatura: Asignatura
    evaluacionInstancias: EvaluacionInstancia[]
    evaluacionInstanciaById: { [id: number]: EvaluacionInstancia }

    evaluacionMultiples: EvaluacionMultiple[]
    evaluacionMultiple: EvaluacionMultiple

    constructor(
        protected estadisticasApiService: EstadisticasEvolutivasService,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected estadisticasService: Estadisticas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected clasificacionesService: Clasificaciones,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        protected grupoRecursosService: GrupoRecursos,
        protected evaluacionMultiplesService: EvaluacionMultiples
    ) {}

    ngOnInit() {
        this.enableResponsive = window.innerWidth <= 800

        this.loadingLayout.ready()
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 800
    }

    onAsignaturaChange(asignatura: Asignatura) {
        this.asignatura = asignatura

        this.setData()
    }

    async setData() {
        this.loadingLayout.standby()
        const usuarioId = this.authService.getUserData().id

        const params = {
            grupo_recurso: {
                recurso_type: "EvaluacionMultiple"
            },
            grupo_recurso_tipo: {
                grupo_recurso_tipo: "prueba de practica"
            }
        }

        this.grupoRecursosService.enableIgnoreModel()
        const grupoRecursos = await this.grupoRecursosService.where(params)
        this.grupoRecursosService.disableIgnoreModel()

        const evaluacionMultipleParams = {
            evaluacion_multiple: {
                id: grupoRecursos.map(gr => gr.recurso_id)
            },
            include: "[evaluacion_tipo,evaluaciones:[instrumento]]"
        }

        let evaluacionMultiples = await this.evaluacionMultiplesService.where(evaluacionMultipleParams)
        const evaluacionMultipleById = evaluacionMultiples.reduce((acc, em) => {
            acc[em.id] = em

            return acc
        }, {})

        evaluacionMultiples = grupoRecursos.map(gr => evaluacionMultipleById[gr.recurso_id])

        const evaluaciones = evaluacionMultiples
            .map(em => em.evaluaciones)
            .flat()
            .filter(e => e.instrumento.asignatura_id == this.asignatura.id)

        const evaluacionInstanciaParams = {
            evaluacion_instancia: {
                evaluacion_id: evaluaciones.map(e => e.id),
                usuario_id: usuarioId
            }
        }
        const evaluacionInstancias = await this.evaluacionInstanciasService.where(evaluacionInstanciaParams)
        const evaluacionInstanciasOficiales = evaluacionInstancias.filter(ei => ei.oficial)

        this.dataSets = [
            {
                nombre: this.asignatura.asignatura,
                data: evaluacionInstanciasOficiales.map(ei => {
                    return [
                        new Date(ei.created_at).toLocaleDateString("es-CL"),
                        (ei.correctas / (ei.correctas + ei.incorrectas + ei.omitidas)) * 100
                    ]
                })
            }
        ]

        this.evaluacionMultiples = evaluacionMultiples
        this.evaluacionMultiple = evaluacionMultiples[0]

        this.evaluacionInstancias = evaluacionInstancias

        this.onEvaluacionMultipleSelected()

        this.loadingLayout.ready()
    }

    onEvaluacionMultipleSelected() {
        this.dataSetsIntentos = null

        const evaluacion = this.evaluacionMultiple.evaluaciones.find(
            e => e.instrumento.asignatura_id == this.asignatura.id
        )

        const evaluacionInstancias = this.evaluacionInstancias
            .sort((ei1, ei2) => ei1.nintento - ei2.nintento)
            .filter(ei => evaluacion && ei.evaluacion_id == evaluacion.id)

        this.dataSetsIntentos = [
            {
                nombre: this.asignatura.asignatura,
                data: evaluacionInstancias.map(ei => {
                    return [
                        new Date(ei.created_at).toLocaleDateString("es-CL"),
                        (ei.correctas / (ei.correctas + ei.incorrectas + ei.omitidas)) * 100
                    ]
                })
            }
        ]
    }
}
