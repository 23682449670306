<ng-container *ngIf="!saved">
    <form novalidate *ngIf="form" [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuario['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuario['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['fecha_nacimiento']"
                    [(value)]="usuario['fecha_nacimiento']"
                    [key]="'fecha_nacimiento'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuario['email']"
                    [key]="'email'"
                ></form-input>
            </div>
            <div class="col-md-6">
                <form-input
                    [form]="form"
                    [params]="params['pais_id']"
                    [(value)]="usuario['pais_id']"
                    [key]="'pais_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuario['password']"
                    [key]="'password'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuario['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <button (click)="checkTelefono()" type="button" class="btn btn-default btn-block submit-btn">
                    Actualizar
                </button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved && mailChanged">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>

<ng-container *ngIf="saved && !mailChanged">
    Tus datos han sido actualizados correctamente. En unos segundos te redirigiremos a la página principal.
</ng-container>

<ng-container *ngIf="openModal">
    <generic-modal [buttonClicked]="openModal">
        <modal-titulo>Advertencia</modal-titulo>
        <modal-contenido>
            <p>Te sugerimos agregar tu celular para recuperar tu contraseña con SMS.</p>
        </modal-contenido>
        <modal-botones>
            <button class="btn btn-default" (click)="genericModal.close()">Agregar Celular</button>
            <button class="btn btn-default" (click)="goOmitir()">Omitir</button>
        </modal-botones>
    </generic-modal>
</ng-container>
