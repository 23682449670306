<div *ngIf="usuarioPaa">
    <ng-container>
        <cui-section-headline>Resultados PAA</cui-section-headline>
        <ul>
            <li><p>Año:</p></li>
            <li>{{ usuarioPaa.year | easyplaceholder: "-" }}</li>
            <li><p>Ubicación:</p></li>
            <li>{{ usuarioPaa.ubicacion | easyplaceholder: "-" }}</li>
            <li><p>Resultado Matemáticas:</p></li>
            <li>{{ usuarioPaa.resultado_matematicas | easyplaceholder: "-" }}</li>
            <li><p>Resultado Lectura Redacción:</p></li>
            <li>{{ usuarioPaa.resultado_lectura_redaccion | easyplaceholder: "-" }}</li>
            <li><p>Resultado Inglés:</p></li>
            <li>{{ usuarioPaa.resultado_ingles | easyplaceholder: "-" }}</li>
        </ul>
    </ng-container>
</div>
