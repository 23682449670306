<div class="contenedor">
    <div class="mascara">
        <!-- Login -->
        <div id="section1">
            <div class="caja caja-inicio">
                <div class="caja-inner-margin init-top-padding">
                    <div class="login-placement cb-landing">
                        <login-cb
                            [enableContactUs]="false"
                            [enableRegister]="true"
                            [enablePasswordRecovery]="true"
                            [validarIdentificador]="false"
                        ></login-cb>
                    </div>
                    <div class="caja-center">
                        <div class="logo-landing">
                            <div class="logo-wrap">
                                <img
                                    class="logo"
                                    src="{{ config.app.assets.logoLarge }}"
                                    alt="{{ config.plataforma.info.companyName }}"
                                    title="{{ config.plataforma.info.companyName }}"
                                />
                            </div>
                        </div>
                        <div class="logo-partnership">
                            <p>En alianza con:</p>
                            <div class="logo-wrap">
                                <a href="https://latam.collegeboard.org" target="_blank">
                                    <img
                                        class="logo"
                                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/logos/logoCB.png"
                                        alt="College Board"
                                        title="College Board"
                                        lang="en"
                                    />
                                </a>
                            </div>
                        </div>
                        <h1 class="titulo">
                            Prepárate
                            <em>gratuitamente</em>
                            para la prueba de admisión universitaria, la
                            <b>PAA</b>
                        </h1>
                        <div class="deco-long">
                            <img src="assets/img/landing/deco_long.png" aria-label="decoration" />
                        </div>

                        <cui-button size="large" class="btn-comenzar" (click)="goToRegister()">
                            Comienza a practicar
                        </cui-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="caja seccion_info" id="section2">
            <div class="seccion-wrap">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="info-with-padding">
                            <h2>¿Qué es la PAA?</h2>
                            <p>
                                La
                                <b>PAA</b>
                                es una prueba que evalúa las habilidades y los conocimientos que necesitas para hacer
                                trabajo académico de nivel universitario.
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="image-wrap">
                            <img
                                alt="¿Qué es la PAA?"
                                title="¿Qué es la PAA?"
                                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/landing/v2/que_es_la_paa.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Info -->
        <div class="caja seccion_info_practica" id="section3">
            <div class="seccion-wrap">
                <h2>¿Qué son las prácticas para la PAA?</h2>
                <div class="info-container">
                    <div class="info-wrap" anim-on-scroll-enter-from-left>
                        <div class="img-wrap">
                            <img
                                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/landing/v2/icon-practicas-oficiales_2.png"
                                alt="Prácticas oficiales"
                            />
                        </div>
                        <div>
                            <h3>
                                Prácticas
                                <em>oficiales</em>
                            </h3>
                            <p>
                                Desarrollamos los materiales de este portal en una alianza con el College Board, la
                                organización que crea y administra la PAA.
                            </p>
                        </div>
                    </div>
                    <div class="info-wrap" anim-on-scroll-enter-from-right>
                        <div class="img-wrap">
                            <img
                                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/landing/v2/icon-practicas-gratuitas.png"
                                alt="Prácticas gratuitas"
                            />
                        </div>
                        <div>
                            <h3>
                                Prácticas
                                <em>gratuitas</em>
                            </h3>
                            <p>
                                Te ofrecemos los materiales del portal en cualquier lugar, a cualquier hora y sin costo
                                alguno.
                            </p>
                        </div>
                    </div>
                    <div class="info-wrap" anim-on-scroll-enter-from-left>
                        <div class="img-wrap">
                            <img
                                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/landing/v2/icon-practicas-digitales.png"
                                alt="Prácticas digitales"
                            />
                        </div>
                        <div>
                            <h3>
                                Prácticas
                                <em>digitales</em>
                            </h3>
                            <p>
                                Puedes trabajar con estos materiales en una computadora, en una tableta, en un teléfono…
                                ¡como preﬁeras!
                            </p>
                        </div>
                    </div>
                    <div class="info-wrap" anim-on-scroll-enter-from-right>
                        <div class="img-wrap">
                            <img
                                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/landing/v2/icon-practicas-personalizadas.png"
                                alt="Prácticas personalizadas"
                            />
                        </div>
                        <div>
                            <h3>
                                Prácticas
                                <em>personalizadas</em>
                            </h3>
                            <p>
                                El portal se ajusta a ti: según contestes pruebas y ejercicios, tendrás planes de
                                estudio personalizados para potenciar tus habilidades.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Info PAA -->
        <div class="caja seccion_info_paa" id="section4">
            <div class="seccion-wrap">
                <div class="grid-layout">
                    <div class="col1">
                        <div anim-on-scroll-enter-from-top>
                            <h2>
                                Da lo mejor de ti el día de la prueba…
                                <b>¡y más allá!</b>
                            </h2>
                            <p>
                                Queremos que entres a la prueba con confianza en tus habilidades.
                                <br />
                                <b>¡Aprovecha al máximo tus oportunidades!</b>
                                Lo que aprendas para la PAA te va a ayudar en la universidad y en la vida, cualquiera
                                que sea la carrera que elijas.
                            </p>
                        </div>
                    </div>
                    <div class="col2">
                        <div class="image-wrap" anim-on-scroll-enter-from-bottom>
                            <img
                                alt="Estudia para la PAA"
                                title="Estudia para la PAA"
                                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/college_board/landing/v2/estudia_para_la_paa_2.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer-component></footer-component>
</div>
<simple-modal></simple-modal>
<popup-cookies></popup-cookies>
