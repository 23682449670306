<el-topside>
    <evaluacion-tiempo
        *ngIf="evaluacion && loadClock && !showInstruccion && !showWait"
        [evaluacion]="evaluacion"
        (onTimeUp)="onTimeup()"
        (onReady)="onTiempoReady($event)"
        [enableToggle]="false"
        [enableFixed]="false"
        [animateOnStart]="false"
    ></evaluacion-tiempo>
</el-topside>

<loading-layout>
    <progreso-preguntas-evaluacion-secuencial
        [class.hidden]="hideProgreso"
        class="theme-cb"
    ></progreso-preguntas-evaluacion-secuencial>

    <ng-container *ngIf="showInstruccion">
        <div class="instruccion">
            <h2>Instrucciones</h2>
            <p>
                La PAA es una prueba de evaluación que les facilita a las instituciones de educación superior, en Puerto
                Rico y América Latina, los procesos de admisión y ubicación de estudiantes. La PAA consta de cuatro
                áreas (Lectura, Redacción, Matemáticas e Inglés) e integra, en un solo instrumento, los componentes de
                razonamiento y aprovechamiento.
            </p>
            <p>
                La
                <i>Prueba de práctica</i>
                se elaboró con la misma cantidad de ejercicios, la misma variedad de temas y el mismo nivel de
                dificultad que la prueba verdadera de la PAA.
            </p>
            <p>
                Esta prueba de práctica será de más ayuda si la toma bajo condiciones tan parecidas como sea posible a
                las que tendrá cuando tome la PAA:
            </p>
            <ul>
                <li>
                    Separe un periodo ininterrumpido de tiempo, de modo que pueda contestar toda la prueba de una sola
                    vez.
                </li>
                <li>Marque una sola respuesta por ejercicio.</li>
                <li>
                    Puede pasar de un ejercicio a otro presionando los botones “Anterior” o “Siguiente”, seleccionando
                    el número de la pregunta o usando las flechas del teclado.
                </li>
                <li>
                    En un ejercicio, puede seleccionar la respuesta correcta seleccionando la opción o escribiendo la
                    letra en el teclado.
                </li>
            </ul>
            <p>
                En los ejercicios de suplir la respuesta de la parte de Matemáticas deberá producir su propia respuesta
                en lugar de seleccionarla.
            </p>
            <p>
                El tiempo de la prueba comenzará a contabilizarse a penas presione el botón continuar. Podrá tomar un
                descanso breve al finalizar cada una de las partes de la práctica.
            </p>
        </div>
        <div class="btn-bar">
            <button class="btn btn-default btn_style" (click)="next()">Continuar</button>
        </div>
    </ng-container>
    <ng-container *ngIf="showWait">
        <div class="waitMessage instruccion">
            <p>Descanse un momento antes de continuar con la siguiente parte.</p>
            <p>El tiempo comenzará a contabilizarse a penas presione el botón continuar.</p>
            <div class="btn-bar">
                <button class="btn btn-default btn_style" (click)="next()">Continuar</button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="showTimeup">
        <div *ngIf="evaluaciones.length - 1 != evaluacionIndex" class="instruccion">
            <p>Se terminó el tiempo. En unos segundos serás redireccionado a la siguiente parte.</p>
        </div>
        <div *ngIf="evaluaciones.length - 1 == evaluacionIndex" class="instruccion">
            <p>Se terminó el tiempo. En unos segundos la evaluación terminará.</p>
        </div>
    </ng-container>
    <evaluacion-secuencial
        [class.hidden]="showTimeup"
        *ngIf="!showInstruccion && !showWait"
        [evaluacionId]="evaluacionId"
        [evaluacionTiempo]="evaluacionTiempo"
        (onReadyEvaluacion)="onReadyEvaluacion($event)"
        (onLoadClockChange)="onLoadClockChange($event)"
        (evaluacionEntregada)="onEvaluacionEntregada($event)"
        [enableBtnMarkAsUncertain]="enableBtnMarkAsUncertain"
        [enablePreguntasId]="false"
        class="with-instructions theme-cb"
        [completarButton]="completarButton"
    ></evaluacion-secuencial>
</loading-layout>
